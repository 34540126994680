@app.service 'csIngredientService', (csQuantityService) ->

  @toIngredient = (ingredient) ->
    quantityVal = parseFloat(ingredient.quantity)
    quantity = csQuantityService.toScalableQuantity quantityVal, ingredient.unit

    if ingredient.unit == 'recipe' || ingredient.subActivity
      console.log 'ingredient: ', ingredient
      id: ingredient.subActivity?.id
      title: ingredient.title
      note: ingredient.note
      url: ingredient.subActivity?.url
      imageUrl: ingredient.subActivity?.image
      productUrl: ingredient.subActivity?.productUrl
      origQuantity: _.cloneDeep quantity
      quantity: _.cloneDeep quantity
      subActivity: ingredient.subActivity
    else
      id: ingredient.ingredient.id
      title: ingredient.title
      note: ingredient.note
      url: ingredient.ingredient.url
      imageUrl: ingredient.ingredient.image
      productUrl: ingredient.ingredient.productUrl
      origQuantity: _.cloneDeep quantity
      quantity: _.cloneDeep quantity

  @rescaleIngredient = (ingredient, factor) ->
    ingredient.quantity = csQuantityService.scaleQuantity ingredient.origQuantity, factor

    ingredient

  this
