@app.directive 'timeTempBar', (TimeTempService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'timeTempBar'
  controller: ->

    @axis = TimeTempService.axis

    @calcPosition = TimeTempService.calcPosition

    @spanStyle =
      width: @calcPosition(@high) - @calcPosition(@low) + '%'
      left: @calcPosition(@low) + '%'

    @recommendedStyle =
      left: @calcPosition(@recommended) + '%'

    @highStyle =
      left: @calcPosition(@high) + '%'

    @lineStyle =
      width: @calcPosition(@low) + '%'

    @markerStyle = (time) =>
      styles =
        left: @calcPosition(time) + '%'
      styles

    @showMarker = (time) =>
      if @low <= time <= @high
        false
      else
        true

    this

  bindToController: {
    low: '='
    high: '='
    recommended: '='
  }
  templateUrl: "/assets/templates/directives/timeTempGuide/timeTempBar.html"

@app.filter 'decimalToTime', ->
  (input) ->
    integer = Math.floor(input)
    decimal = input % 1

    if integer == 0
      hoursString = '00'
    else
      hoursString = "#{integer}"

    minutes = "#{Math.round(decimal*60)}"

    if minutes < 10
      minutesString = "0#{minutes}"
    else
      minutesString = "#{minutes}"

    "#{hoursString}:#{minutesString}"
