@app.directive 'timeTempItem', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'timeTempItem'
  controller: ->

    this

  bindToController: {
    ingredientType: '='
    title: '='
    temp: '='
    time: '='
  }
  templateUrl: "/assets/templates/directives/timeTempGuide/timeTempItem.html"
