@app.directive  'sendToMessenger', (authenticationService, AnalyticsService, csConfig) ->
  restrict: 'E'
  scope: {}
  bindToController:
    cmd: '@'
    body: '@'
  controllerAs: 'sendToMessenger'
  templateUrl: "/assets/templates/directives/shortcodeOutputs/csSendToMessenger/sendToMessenger.html"

  controller: ->
    @open = =>
      AnalyticsService.track 'Send To Messenger', {cmd: @cmd}
      window.open "#{csConfig.messengerEndpoint}?ref=#{JSON.stringify(cmd: @cmd)}", "_blank"
      true

    @getMessage = ->
      msg = @cmd
      msg = msg[0].toUpperCase() + msg[1..]
      "&ldquo;#{msg}&rdquo;"

    @getBody = ->
      @body || "Pick your perfect doneness using Joule on Facebook Messenger"

    this