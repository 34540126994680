@app.controller 'SubscriptionsController', ($state, subscriptionService, localStorageService, $location) ->
  @loading = true
  @subscriptions = []
  @isPremium = $state.currentUser?.premium
  @isStudio = $state.currentUser?.studio
  @scheduledList = $state.currentUser?.scheduled
  @renewalDate = ''
  @showDropDown = false
  @dropdownClass = 'fa-caret-right'
  @showError = false
  @showSpinner = false
  @errorType = ''
  @originalPlanType = ''
  @showPopUp = false
  @currentStatus = ''
  @currentPlanType = ''
  @inTrail = false
  @trailEndDate = null
  @subscriptionEndDate = null
  @scheduledCancel = false
  @canApplyCoupon = false
  @pricing = {
    Monthly: "$7/month",
    Annual: "$69/year"
  }
  @duration = {
    Monthly: "month",
    Annual: "year"
  }
  @canManageSub = false
  @canModifyPlan = false
  @isCancelled = false
  @canShowRenewbtn = false
  @canShowReactivatebtn = false
  @offerFromMail = null
  @offerType = null
  @bottomRenewbtn = false

  offerMailSrc = {
    "save20": "20days"
    "save20plus1": "14days"
  }

  offerTextConf = {
    '14days': {
      topic: "<p class='offer-line'>Save $20</p> <p class='offer-line-sub'> and Share Studio Pass with a Friend.</p>"
      body: "Renew your <b>Studio Pass subscription today for $49 </b> (valued at $69) and recieve a free Studio Pass trial offer to share. Trial offer good for new subscribers only. Credit card required to start. Total savings of $89!"
    },
    '20days': {
      topic: "<p class='offer-line'>Save $20</p>",
      body: "Renew your <b> Studio Pass membership </b>today for $49 and save $20 on your annual subscription fee."
    },
    '1day': {
      topic: "<p class='last-save'>Last chance <br>to save!</p> <p class='last-save-sub'> Renew and save $20</p>",
      body: "Renew your <b>Studio Pass membership </b> today for $49 and save $20 on your annual subscription fee. Offer valid for 14 days from subscription expiration."
    }
  }
  greetTextConf = {
    '20days': {
      topic: "<p class='greet-line'>Thank you for renewing.</p>",
      body: "Your <b> Studio Pass annual subscription </b> has been renewed. <br/> Now let’s <a href='/gallery'>get cooking.</a>"
    },
    '14days': {
      topic: "<p class='greet-line'>Thank you for renewing.</p>",
      body: "Your <b> Studio Pass annual subscription </b> has been renewed. An email was sent with your referral code. <br/> <i>Code is valid for 30 days.</i>"
    },
    '1day': {
      topic: "<p class='greet-line'>Thank you for renewing.</p>",
      body: "Your <b> Studio Pass annual subscription </b> has been renewed. <br/>  Now let’s <a href='/gallery'>get cooking.</a>"
    }
  }
  @offerText = ''

  @init = =>
    @showCancelGreet = localStorageService.get('chargeBeeGreetMessage')
    @offerFromMail = offerMailSrc[$location.search().offer]
    subscriptionService.syncSubscriptions()
      .then (res) =>
        @loading = false
        @currentStatus = _.get(res, 'current_status')
        @scheduledCancel = _.get(res, 'scheduled_cancel')
        @subscriptions = $state.currentUser?.subscriptions || []
        @isPremium = $state.currentUser?.premium
        @scheduledList = $state.currentUser?.scheduled
        @renewalDate = $state.currentUser?.next_billing_date
        @currentPlanType = $state.currentUser?.plan_type
        @scheduleType = 'Annual'
        @originalPlanType = _.get(@scheduledList[0], 'type', @currentPlanType)
        @trailEndDate = _.get(res, 'trail_end_date')
        @inTrail = !_.isEmpty(@trailEndDate)
        if _.isEmpty(@scheduledList)
          @scheduledList = if $state.currentUser?.plan_type && @renewalDate then [{type: $state.currentUser?.plan_type, starts_at: @renewalDate}] else []
        @isStudio = $state.currentUser?.studio
        @isCancelled = @currentStatus == 'cancelled'
        @subscriptionEndDate = _.get(res, 'subscription_end_date')
        if !_.isEmpty(@scheduledList[0])
          @amount = _.get(@scheduledList[0], 'amount')
          # To check amount is float or whole num
          # if decimal show in 2 decimal points
          if @amount%1 !=0
            @amount = @amount.toFixed(2)
        if (@scheduledCancel || @isCancelled) && !_.isEmpty(@subscriptionEndDate) && @originalPlanType != 'Monthly'
          @applyOffer()
        else
          @showOfferGreet()
        @anyCancelled = $state.currentUser?.subscriptions.map((subscription) ->
          subscription['status']
        ).includes 'cancelled'
        @canManageSub = (!@scheduledCancel && _.includes(['in_trial', 'active'], @currentStatus)) || @isCancelled
        @canModifyPlan = @currentPlanType == 'Monthly' && @originalPlanType == 'Monthly' #(@isStudio || @isCancelled) && !@inTrail
      .catch (error) =>
        @loading = false

  @resumeSubscription = =>
    @showPopUp = true
    @showSpinner = true
    subscriptionService.removeScheduleCancel(@offerType)
      .then (res) ->
        localStorageService.set('renewalOfferApplied', res.data.offer_applied)
        subscriptionService.syncSubscriptions().then (_) ->
          $state.reload($state.current)

  @onDismiss = =>
    @showError = false
    @showPopUp = false

  @submit = ->
    unless @scheduleType == @originalPlanType
      @showPopUp = true
      @showSpinner = true
      @toggleDropDown()
      coupon_code = if @couponCode then @couponCode.trim() else null
      subscriptionService.switchSubscription(@scheduleType, coupon_code)
        .then (res) =>
          @showSpinner = false
          @loading = true
          @init()
        .catch (error) =>
          @showError = true
          @showSpinner = false
          @errorType = if error.status == 400 then _.get(error, 'data.message') else 'oops'
          @errorMsg = if @errorType == 'UPDATE_FAILED' then _.get(error, 'data.error_message') else ''

  @toggleDropDown = ->
    @showDropDown = !@showDropDown
    @dropdownClass = if @showDropDown then 'fa-caret-down' else 'fa-caret-right'

  @discard = ->
    @scheduleType = @originalPlanType

  @showOfferText = =>
    dateDiff = moment(@subscriptionEndDate).diff(moment(), 'days')
    @canShowRenewbtn = 'bottom'
    if (20 >= dateDiff) && (14 < dateDiff)
      @offerText = offerTextConf['20days']
      @offerType = '20days'
    else if (14>= dateDiff) && (1 < dateDiff)
      @offerText = offerTextConf['14days']
      @offerType = '14days'
    else if (@isCancelled && moment().diff(@subscriptionEndDate, 'days') <= 14)
      @offerText = offerTextConf['1day']
      @offerType = '1day'
    else if (@isCancelled && moment().diff(@subscriptionEndDate, 'days') > 10)
      @bottomRenewbtn = true
    else if @isCancelled
      @canShowReactivatebtn = true
    else
      @canShowRenewbtn = 'top'

  @applyOffer = =>
    if !_.isEmpty(@offerFromMail) && @scheduledCancel
      dateDiff = moment(@subscriptionEndDate).diff(moment(), 'days')
      @canShowRenewbtn = 'bottom'
      if @offerFromMail == '20days' && dateDiff <= 20
        @offerText = offerTextConf['20days']
        @offerType = '20days'
      else if @offerFromMail == '14days' && dateDiff <= 14
        @offerText = offerTextConf['14days']
        @offerType = '14days'
      else
        @showOfferText()
    else
      @showOfferText()

  @showOfferGreet = =>
    offerApplied = localStorageService.get('renewalOfferApplied')
    if @showCancelGreet
      @offerText = greetTextConf['1day']
    else if offerApplied == '14days'
      @offerText = greetTextConf['14days']
      localStorageService.set('renewalOfferApplied', false)
    else if offerApplied == '20days'
      @offerText = greetTextConf['20days']

    localStorageService.set('chargeBeeGreetMessage', false)
    localStorageService.set('renewalOfferApplied', false)
    @canShowReactivatebtn = false

  @init()

  this
