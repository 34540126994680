@app.service 'AnalyticsService', ($rootScope, $stateParams, csConfig, $state, $location, authenticationService, $timeout, ExperimentServiceCore, CookieConsentService) ->
  
  @initialized = false

  @loggedIn = -> $state.currentUser?
  @currentUserId = -> $state.currentUser?.id
  @studio = -> $state.currentUser?.studio
  @premium = -> $state.currentUser?.premium

  @init = ->
    if CookieConsentService.canDoAnalytics() && !@initialized
      @initialized = true
      analytics.load(csConfig.segmentWriteKey)
      analytics.ready ->
        Rollbar?.configure
          payload:
            environment: csConfig['env']
            client:
              javascript:
                source_map_enabled: true
                code_version: csConfig['deploySHA']
                guess_uncaught_frames: true

  @identify = (user) ->
    if false #CookieConsentService.canDoAnalytics()
      # Identify must be called with user.id
      # Using something other than user.id (like user.email) can cause analytics services to be confused.
      analytics.identify user.id, _.extend(_.omit(user, ["id"]), getExperimentProperties())

  @reidentify = (signup = false) =>
    if false #CookieConsentService.canDoAnalytics()
      authenticationService.me().then (user) =>
        # According to https://segment.com/docs/integrations/mixpanel/, https://help.segment.com/hc/en-us/articles/204812929#common-issues
        # https://help.segment.com/hc/en-us/articles/204812929, we should do alias before identify, and *ONLY* on signup.

        if signup

          # We are experiencing a lot of problems with identify and alias ending up in the wrong order over the wire
          # and segment/mixpanel not sorting them correctly based on original event time, creating broken
          # funnels in mixpanel. This is an attempt to remedy that, *only* on signup.
          analytics.alias(user.id)
          $timeout ( =>
            @identify(user)
          ), 500

        else
          @identify(user)

  @track = (event, properties = {}) ->
    if false #CookieConsentService.canDoAnalytics()
      allProperties = _.extend(properties, analyticsUserId?.load(@currentUserId()), analyticsUserPlan?.load(@loggedIn(), @studio(), @premium()), {url: $state.current.url, loggedIn: @loggedIn()}, {nonInteraction: 1})
      analytics.track event, allProperties

  @page = (pageName, properties = {}) ->
    if false #CookieConsentService.canDoAnalytics()
      allProperties = _.extend(properties, analyticsUserId?.load(@currentUserId()), analyticsUserPlan?.load(@loggedIn(), @studio(), @premium()), {slug: $stateParams.id || '', loggedIn: @loggedIn()})
      analytics.page pageName, allProperties

  getExperimentProperties = ->
    result = {}
    for k,v of ExperimentServiceCore.getExperiments()
      result['Experiment ' + k] = v.variationName
    result

  # Track location changes in the SPA as page views. Don't track them
  # if location didn't actually change. Don't use the oldURL passed to this
  # event for two reasons:
  # (1) on initial load, newURL == oldURL, which is just dumb
  # (2) it changes on query param changes which is now what we want for gallery
  @prevPath = null
  $rootScope.$on '$locationChangeSuccess', (event, newURL) =>
    newPath = $location.path()
    unless (newPath == @prevPath)
      @prevPath = newPath
      pageName = $state.current.name.replace /^main./,''
      @page pageName

  # Triggered when user consents to analytics
  $rootScope.$on '$cookieConsentAccept', (event) =>
    @init()

  # Triggered when the information necessary to determine whether tracking can start has loaded
  # At this point we need to re-trigger initialization
  $rootScope.$on '$cookieConsentLoaded', (event) =>
    @init()


  this
