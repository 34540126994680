@app.controller 'JouleLandingHeroController', ($element, $window, $rootScope, LocationService, responsiveService, mobileDetectionService, AnalyticsService, $scope, $interval ) ->
  @content =
    title: "Your Next Kitchen Essential."
    isInStock: ""
    outOfStock: "Out of stock"
    image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/LHLAwBpQvGfv281fldum'
    mobileImage: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/ymSFiIwTQmqHYBTdK2Lk'
    youtubeId: 'QBjjIWMYH8s'
    international:
      description:'Currently available for purchase and shipping within the US only.'
  @theme = 'light'

  cookedText = ["fish", "custards", "pork", "yogurt", "chicken", "steak"]
  cookedCounter = 0

  changeCooked = ->
    cookedElem = document.getElementById('cooked-text')

    # Change element HTML
    cookedElem.innerHTML = cookedText[cookedCounter]

    # Re Initialize Element for animation
    newCookedElem = cookedElem.cloneNode(true)
    cookedElem.parentNode.replaceChild(newCookedElem, cookedElem)

    # Update Counter
    cookedCounter = cookedCounter + 1
    if cookedCounter >= cookedText.length
      cookedCounter = 0

  # Run changeCooked() every 3 seconds. destroy on view change.
  intervalCooked = $interval changeCooked, 2000
  $scope.$on '$destroy', ->
    $interval.cancel(intervalCooked)

  @pressTrack = ->
    AnalyticsService.track 'Joule Press Quotes Shown'


  @getAspect = ->
    return 'portrait' if ($element.children()[0].clientHeight / 9) > ($element.children()[0].clientWidth / 16)
    return 'landscape'

  # Responsive Variables
  @isViewportMobile = responsiveService.isViewportMobile()
  @isMobileDevice = mobileDetectionService.isMobile
  @responsiveWidth = responsiveService
  angular.element($window).bind 'resize', =>
    @isViewportMobile = responsiveService.isViewportMobile()
    $rootScope.$safeApply()

  this
