@app.directive 'signupForm', (ModalService, GtagServiceHelper, AnalyticsService, $state, authenticationService, csAdwords, csFacebookConversion, formHelper, LocationService, ssoService, $location)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @pattern = /.+@.+\..+/i
    @password_pattern = /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/
    @params = ModalService.params()
    @showOptInConsent = _.contains(['doubleOptIn', 'singleOptIn'], LocationService.getConsentType())

    LocationService.loadData()

    @showErrorFor = (fieldName, validationParam) =>
      formHelper.showErrorFor(@form, fieldName, validationParam)

    @close = ->
      ModalService.reject()

    @track = (extraProperties) =>
      csAdwords.track(998032928,'77TfCIjjrAgQoIzz2wM')
      csFacebookConversion.track(6014798030226,0.00)

      AnalyticsService.reidentify(true)

      if @sso
        properties = extraProperties
      else
        properties = angular.extend(ModalService.modal.params, extraProperties)
      AnalyticsService.track 'Signed Up JS', properties

      # Google Analytics
      AnalyticsService.track 'Complete', { category: 'Sign Up' }

    @submit = =>
      registrationSuccess = =>
      
        GtagServiceHelper.signup()
        @track(method: 'standard')

        @showSuccess = true
        @showSpinner = false

        if @sso
          @state = $location.search().state
          ssoService.signAndRedirect(@state).then ((response) ->

          ), (error) =>
            @errorMessage = error
        else
          ModalService.ftueIfFromNav()

      registrationError = (response) =>
        @errorMessage = 'This email is already registered. Want to login or recover your password?'
        @showSpinner = false

      if @form.$valid
        @showSpinner = true
        optIn = if @showOptInConsent then (@form.optin?.$modelValue || false) else true

        authenticationService
          .registerWithEmail @form.name.$modelValue, @form.email.$modelValue, @form.password.$modelValue, optIn
          .then registrationSuccess, registrationError

    @login = ->
      # Transfer params
      ModalService.open('login', ModalService.modal.params)

    @terms = ->
      ModalService.open('terms')

    @isInUS = ->
      LocationService.isInUS()

    this

  controllerAs: 'signup'
  bindToController: {
    sso: '='
    ftue: '='
  }
  templateUrl: "/assets/templates/directives/ssoForm/signupForm/signupForm.html"
