@app.directive 'mediaMulti', ($timeout, AnalyticsService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'mediaMulti'
  controller: ->
    @activeIndex = 0
    @active = true
    @autoplay = false

    @selectMedia = (index) =>
      @active = false
      @activeIndex = index
      @autoplay = true
      $timeout (=>
        @active = true
      ), 100
      if @content.items[index].vimeoId
        AnalyticsService.track 'Video Played - Vimeo ' + @content.items[index].vimeoId
      if @content.items[index].youtubeId
        AnalyticsService.track 'Video Played - Youtube' + @content.items[index].youtubeId

    @activeItem = =>
      @content.items[@activeIndex]

    @toggleActive = =>
      @active = !@active

    @track = ->
      if @content.items[@activeIndex].vimeoId
        AnalyticsService.track 'Video Played - Vimeo' + @content.items[@activeIndex].vimeoId
      if @content.items[@activeIndex].youtubeId
        AnalyticsService.track 'Video Played - Youtube' + @content.items[@activeIndex].youtubeId

    this

  link: (scope, element, attrs) ->

  bindToController: {
    content: '='
    theme: '='
    activeIndex: '='
  }
  templateUrl: "/assets/templates/components/media/mediaMulti.html"
