@app.factory 'csRollbarInterceptor', ($q, csConfig) ->
  apiRE = new RegExp("#{csConfig.chefstepsEndpoint}/api/")

  logError = (msg, rejection) ->
    rejection_info = angular.copy(rejection)
    delete rejection_info.config.headers['Authorization']
    Rollbar?.info msg, rejection_info: rejection_info

  urlFromRejection = (rejection) ->
    url = rejection.config.url

    return '' if ! url

    if url.indexOf("#{csConfig.chefstepsEndpoint}/api/") == 0

      # For known CS API, trim off the protocol and host for brevity
      # (environment is a higher level rollbar property)
      url = url.replace("#{csConfig.chefstepsEndpoint}", '')

      # replace actual slug with SLUG so salmon and beef aren't separate error items
      # Examples:
      # https://www.chefsteps.com/api/v0/activities/sous-vide-salmon becomes /api/v0/activities/SLUG
      # https://www.chefsteps.com/api/v0/activities/sous-vide-salmon/likes becomes /api/v0/activities/SLUG/likes
      url = url.replace(/(activities|ingredients|users)\/([^/]+)/, "$1/SLUG")

    url

  {
    # These are very rare, if they exist at all - I can't find any in the logs. They would only happen
    # if an earlier interceptor did a reject or something like that.
    'requestError': (rejection) ->
      logError("Request error: #{urlFromRejection(rejection)}", rejection)
      return $q.reject(rejection)

    # Common.
    'responseError': (rejection) ->
      # Carefully choosing the text so that rollbar can group items effectively. E.g.
      # Response error 404 GET /api/v0/activities/{SLUG} - Activity Not Found
      msg = "Response error #{rejection.status} #{rejection.config.method} #{urlFromRejection(rejection)}"
      msg = msg + " - #{rejection.data.message}" if rejection.data?.message?
      logError(msg, rejection)
      return $q.reject(rejection)
  }

@app.config ($provide, $httpProvider) ->
  $httpProvider.interceptors.push 'csRollbarInterceptor'
