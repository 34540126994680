@app.directive 'alerts', ($rootScope, alertService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'alerts'
  controller: ($scope) ->
    @alerts = alertService.alerts

    @alertClass = (type) ->
      "alert-#{type}"

    @close = (index) =>
      @alerts.splice(index, 1)
      console.log 'delete alerts.  Alerts: ', @alerts

    this


  bindToController: {
    alertsFor: '@'
    alerts: '='
  }
  templateUrl: "/assets/templates/directives/alerts/alerts.html"
