@app.directive 'bannerJouleNav', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerJouleNav'
  controller: ->


    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/banner/bannerJouleNav/bannerJouleNav.html"
