@app.directive  'csQuantityShortcode', (csQuantityService) ->
  restrict: 'A'
  scope: {}
  bindToController:
    unit: '@'
    quantity: '@'
    displayUnit: '='
    rescaleFactor: '='
  controllerAs: 'csQuantityShortcode'
  controller: ($scope) ->
    @output = ''
    scalableQuantity = csQuantityService.toScalableQuantity @quantity, @unit

    updateOutput = =>
      quantity = csQuantityService.scaleQuantity scalableQuantity, @rescaleFactor
      if quantity.hasMass
        if @displayUnit == 'metric'
          if quantity.g > 0
            @output = "#{quantity.g} g"
          else if quantity.kg > 0
            @output = "#{quantity.kg} kg"
        else if @displayUnit == 'imperial'
          if quantity.lb > 0 and quantity.oz > 0
            @output = "#{quantity.lb} lb #{quantity.oz} oz"
          else if quantity.lb > 0
            @output = "#{quantity.lb} lb"
          else if quantity.oz > 0
            @output = "#{quantity.oz} oz"

      else
        @output = "#{quantity.massless}"
        @output = @output + " #{quantity.unit}" unless quantity.unit == 'ea'

    $scope.$watch (=> @displayUnit), updateOutput
    $scope.$watch (=> @rescaleFactor), updateOutput

    this
  template: "{{csQuantityShortcode.output}}"
