@app.filter 'linkToText', ->
  (input) ->
    anchorTagRegex = /<a+(>|.*?[^?]>).*<\/a+(>|.*?[^?]>)/g
    input = input.replace(anchorTagRegex, (match) ->
      match.replace(/<[^>]+>/gm, '')
    )
    input

@app.filter 'removeTextDecoration', ->
  (input) ->
    removeTags = ['strong','em','i']
    regexString = removeTags.map((tag) -> "<#{tag}+(>|.*?[^?]>).*<\\/#{tag}+(>|.*?[^?]>)").join('|')
    removeTagRegex = new RegExp regexString, 'g'
    input = input.replace(removeTagRegex, (match) ->
      match.replace(/<[^>]+>/gm, '')
    )
    input

# This charLimit filter will:
# 1. Remove the last unclosed html fragment
# 2. Remove any last word fragments
# 3. Remove any last punctuations
# 4. Add an ellipsis
@app.filter 'charLimit', ->
  (input, maxCharNum) ->
    if !input
      ''
    maxCharNum = parseInt(maxCharNum, 10)

    if !maxCharNum
      input

    if input.length <= maxCharNum
      input

    if input.length > maxCharNum
      input = input.substr(0, maxCharNum)
      # console.log 'Initial truncation: ', input

      # Remove last unclosed html fragment
      # Find the last potential opening tag
      lastOpeningTagIndex = input.lastIndexOf('<')

      if lastOpeningTagIndex > 0
        lastFragment = input.substr(lastOpeningTagIndex, input.length)
        # Do not process if the fragment is part of a closing tag from a previous tag
        unless lastFragment[1] == '/'
          closingTagRegex = /<\/[a-zA-Z]+(>|.*?[^?]>)/
          # Remove the frament if it does not have a closing tag
          unless lastFragment.match(closingTagRegex)
            input = input.substr(0, lastOpeningTagIndex)

      # Remove the last word fragment if there is one
      lastspace = input.lastIndexOf(' ')
      if lastspace != -1
        input = input.substr(0, lastspace)
      # console.log 'After last word fragment: ', input

      # Remove last punctuations
      lastchar = input.slice(-1)
      if lastchar in ['.', '?', '!', ',', ':']
        input = input.substr(0, input.length - 1)
      # console.log 'After last punctuations: ', input

      # Add Ellipsis
      input = input + '…'

    if maxCharNum <= 0
      input = ''

    input

@app.filter 'toTrusted', ['$sce', ($sce) ->
  (input) ->
    $sce.trustAsHtml(input)
]
