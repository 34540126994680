@app.service 'utilities', ($window, $filter, CookieService, $location, $state) ->

  @stringToIdentifier = (s) ->
    return "nullIdentifier" if ! s
    s = s.replace(/[^A-Za-z0-9]/g, '')
    return "nullIdentifier" if ! s
    s = s[0].toLowerCase() + s[1..]

  @roundTo = (number, decimalDigits) ->
    mult = Math.pow(10, decimalDigits)
    Math.round(parseFloat(number) * mult) / mult

  @currency = (number) ->
    $filter('currency')(number)

  @penniesToCurrency = (number) ->
    $filter('currency')($filter('priceConversion')(number))

  @clamp = (value, min, max) ->
    Math.min(Math.max(value, min), max)

  @alpha = (value, min, max) ->
    alpha = (value - min) / (max - min)
    @clamp(alpha, 0, 1)

  @smoothstep = (x, xmin, xmax, ymin, ymax) ->
    alpha = @alpha(x, xmin, xmax)
    alpha = alpha * alpha * (3 - (2*alpha))
    result = ymin + (alpha * (ymax - ymin))

  @convertCtoF = (c) ->
    parseFloat(c * 1.8) + 32

  @convertFtoC = (f) ->
    parseFloat(f - 32) / 1.8

  @urlSafeString = (string) ->
    $window.encodeURI(string)

  @secondsToHMS = (s) ->
    return null if ! s?
    s = Math.floor(parseFloat(s))
    m = Math.floor(s / 60)
    s -= m * 60
    h = Math.floor(m / 60)
    m -= h * 60
    {hours: h, minutes: m, seconds: s}

  @formatTimeDisplay = (value) ->
    timeValue = value?.toString() || '--'
    if timeValue.length < 2
      '0' + timeValue
    else
      timeValue

  @formatTime = (t, showSeconds = true) ->

    h = Math.floor(t / 3600)
    t = t - (h * 3600)
    m = Math.floor(t / 60)
    t = t - (m * 60)
    s = Math.floor(t)
    m += 1 if (s >= 30) && (! showSeconds)

    # Three cases:
    #
    # (1) 6h 1m (over an hour we never show seconds)
    if h > 0
      result = "#{h}h&nbsp;#{m}m"

    # (2) 7m 2s
    else if showSeconds
      # Force a non-zero second so user knows we need precision
      s = 1 if s == 0
      result = "#{m}m&nbsp;#{s}s"

    # (3) 43 mins
    else
      result = "#{m}&nbsp;min"

    result

  @htmlDecode = (value) ->
    $('<div/>').html(value).text()

  @getPreheatAlpha = (intakeTemperature, setPointTemperature) ->
    return 0 if not intakeTemperature? or not setPointTemperature?
    min = 19.9
    alpha = (intakeTemperature - min) / (setPointTemperature - min)
    Math.min(Math.max(0, alpha), 1)

  @nearSetpoint = (intakeTemperature, setPointTemperature) ->
    return false if not intakeTemperature? or not setPointTemperature?
    Math.abs(intakeTemperature - setPointTemperature) <= 0.21

  ###
  #
  # @method getIntDisplay
  # @public
  #
  # @description Returns the integer part of a given value in string format
  # Returns '--' if value is not a number
  #
  # @param {string|number} value - A value can be either or string or a number
  #
  # @returns {string} A string representation of the value
  #
  # @example getIntDisplay(4.5) returns 4
  #
  ###
  @getIntDisplay = (value) ->
    value = parseInt(value)
    unless isNaN(value)
      value
    else
      '--'

  ###
  #
  # @method getDecimalDisplay
  # @public
  #
  # @description Returns the first decimal part of a given value in string format, including '.'
  # Returns '' if value is not a number
  #
  # @param {string|number} value - A value can be either or string or a number
  #
  # @returns {string} A string representation of the value
  #
  # @example getDecimalDisplay(4.5) returns .5
  #
  ###
  @getDecimalDisplay = (value) ->
    # Always allow showing the last '.'
    return '.' if value? and value[value.length - 1] == '.'
    value = parseFloat(value)
    unless isNaN(value)
      # Round to first decimal, e.g. 8.69 -> 8.7
      value = value.toFixed(1)
      strArray = value.toString().split('.')
      if strArray.length > 1
        firstDecimal = strArray[1].charAt(0)
        # Omit decimal if it's .0
        if firstDecimal != '0'
          return '.' + firstDecimal
    return ''


  ###
  #
  # @method isValueMoreThanOneDecimal
  # @public
  #
  # @description Returns true if the value has more than one decimal digit
  #
  # @param {string|number} value - A value can be either or string or a number
  #
  # @returns {boolean} True if the value has more than one decimal digit
  #
  # @example isValueMoreThanOneDecimal(4.55) returns true
  #
  ###
  @isMoreThanOneDecimal = (value) ->
    value = parseFloat(value)
    unless isNaN(value)
      strArray = value.toString().split('.')
      if strArray.length > 1
        decimals = strArray[1]
        if decimals.length > 1
          return true
    return false

  ###
  #
  # @method isThreeDigits
  # @public
  #
  # @description Returns true if the value contains three digits
  #
  # @param {string|number} value - A value can be either or string or a number
  #
  # @returns {boolean} True if the value contains three digits, false otherwise
  #
  ###
  @isThreeDigits = (value) ->
    value = parseInt(value)
    unless isNaN(value)
      return (value.toString().length == 3)
    return false

  ###
  #
  # @method computerCircleScaleToViewPort
  # @public
  #
  # @description Given a diameter of a circle element, which is positioned in the corner of the app,
  # Calculate the scale / multiplier it would require to expand the circle to cover the entire view port
  # e.g. A scale of 5 indicates that the circle element would have to be five times its original size in order to cover the entire view port
  #
  # @param {number} diameter - The diameter of the circle element in px unit
  #
  # @returns {number} scale - the scale / multipler it would require to expand the circle to cover the entire view port
  #
  ###
  @computerCircleScaleToViewPort = (diameter) ->
    # Compute the scale according to view port
    # 1. Use Pythagorean Theorem to compute the diagonal of the view port
    # 2. Scale = view port diagonal / (diameter / 2) since its radius is 50%
    scale = Math.sqrt(Math.pow($window.innerHeight, 2) + Math.pow($window.innerWidth, 2)) / (diameter / 2)
    # Round to one decimal
    scale = Math.round(scale * 10) / 10
    return scale

  @customSanitize = (htmlText, headTag) ->
    return htmlText if _.isEmpty(htmlText)

    conf = {
      "<b>": "<span class='strong'>",
      "<i>": "<span class='italic'>",
      "</b>": "</span>",
      "</i>": "</span>"
    }

    headConf = {
      "<h2": "<div><span class='h2-block'",
      "</h2>": "</span></div>",
      "<h3": "<div><span class='h3-block'",
      "</h3>": "</span></div>",
      "<h4": "<div><span class='h4-block'",
      "</h4>": "</span></div>",
      "<h5": "<div><span class='h5-block'",
      "</h5>": "</span></div>",
      "<h6": "<div><span class='h6-block'",
      "</h6>": "</span></div>"
    }

    conf = _.merge(conf, headConf) if headTag == true

    _.each(conf, (value, key, _obj) ->  htmlText = htmlText.replace(new RegExp(key, 'g'), value) )
    return htmlText

  @maybeShowComponent = (user, showTo, pageId) ->
    showComponent
    switch showTo
      when 'everyone'
        showComponent = true
      when 'premium'
        showComponent = user?.premium && !user?.studio
      when 'non-premium'
        showComponent = !user?.premium
      when 'studio'
        showComponent = user?.studio
      when 'non-studio'
        showComponent = !user?.studio
      when 'admin-only'
        showComponent = false
      when 'non-purchased-&-non-subscribed'
        showComponent = !(user?.studio || user?.premium || _.includes(user?.purchased_classes,pageId))
      when 'paid'
        showComponent = user?.studio || user?.premium
      when 'free'
        showComponent = (!user?.studio && !user?.premium)
      when undefined
        showComponent = true
    return showComponent

  @showCountryBasedComponent = (user, showTo, countries) ->
    country_code = $location.search().country_code
    if country_code
      user_location = country_code
    else
      user_location = 'US'
      if !_.isEmpty(CookieService.get('cs_geo'))
        cs_geo = JSON.parse(CookieService.get('cs_geo'))
        user_location = _.get(cs_geo, 'country', 'US')
      unless _.some($state.internationalData.countries, {'code': user_location})
        user_location = 'US'
    showComponent
    switch showTo
      when 0
        showComponent = true
      when 1
        showComponent = _.includes(countries, user_location)
      when 2
        showComponent = !_.includes(countries, user_location)
    return showComponent

  @showScheduledComponent = (scheduleStartDate, scheduleEndDate) ->
    if scheduleStartDate == null && scheduleEndDate == null
      return true
    else
      scheduleDate = $location.search().schedule_date || new Date()
      start_date = moment(scheduleStartDate).toDate()
      end_date = moment(scheduleEndDate).toDate()
      schedule_date = moment(scheduleDate).startOf('day').toDate()
      if start_date <= schedule_date && end_date >= schedule_date
        return true
      else if start_date <= schedule_date && scheduleEndDate == null
        return true
      else
        return false

  return this
