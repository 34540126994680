@app.directive 'pageNav', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'pageNav'
  controller: ->
    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    type: '='
  }
  templateUrl: "/assets/templates/components/pageNav/pageNav.html"
