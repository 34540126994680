@app.service 'LocationService', ($q, $http, csConfig, $cookies) ->

  @location = null
  @forceUS = false

  @loadData = =>
    deferred = $q.defer()
    if @location?
      deferred.resolve(@location)
    else
      csGeo = $cookies.getObject("cs_geo")
      if(csGeo)
        @location = csGeo
        deferred.resolve(@location)
      else
        # This ChefSteps API currently returns a null location in development
        # { country: nil, latitude: nil, longitude: nil, city: nil, state: nil, zip: nil }
        # This makes it difficult to test first page load when the cs_geo cookie is not set
        #
        # The ChefSteps API will be changed to return a fixed location (ChefSteps office)
        # when in development
        $http.get("#{csConfig.chefstepsEndpoint}/api/v0/locations.json").then(
          (response) =>
            @location = response.data
            deferred.resolve(@location)
          ,(error) ->
            deferred.reject(error)
      )
    deferred.promise

  # Location awareness methods
  @getCountry = =>
    @location?.country

  @isInUS = =>
    (@forceUS || (@getCountry() == 'US') || (@getCountry() == null))

  @getConsentType = =>
    country = if (@forceUS || @getCountry() == null) then 'US' else @getCountry()
    # AU -> Australia, DE -> Germany
    {
      US: "basic",
      AU: "doubleOptIn",
      DE: "doubleOptIn"
    }[country] || 'singleOptIn'

  @forceInUS = ->
    @forceUS = true

  this
