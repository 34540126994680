@app.directive 'signinButtonForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'signinButtonForm'
  controller: ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/signinButton/signinButtonForm.html"
]
