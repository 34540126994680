@app.directive 'downloadButton', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'downloadButton'
  controller: ->
    fileContent = 'file content for example'
    blob = new Blob([ fileContent ], { type : 'text/plain' })
    @blobUrl = (window.URL || window.webkitURL).createObjectURL( blob )
  bindToController: {
    label: '='
    url: '='
    filename: '='
    theme: '='
  }
  templateUrl: "/assets/templates/components/customButton/downloadButton/downloadButton.html"
]
