@app.controller 'matrixFilterFormController', (ComponentService)->
  @itemTypeOptions = ['Media', 'List', 'Circle', 'Popup']

  @applyClass = =>
    matrixClass = []
    matrixClass.push "matrix-#{@columns}-col"

    matrixClass.push "matrix-#{@itemType.toLowerCase()}"
    matrixClass.join ' '

  @setItem = (selectedItem, currentItem)->
    angular.forEach currentItem.content, (value, key) ->
      if selectedItem.hasOwnProperty(key)
        currentItem.content[key] = selectedItem[key]

  @setNumItems = =>
    @numItems = @rows*@columns
    delta = @numItems - @items.length
    matrixItem = ComponentService.newItem('matrix-filter')

    # If there are not enough items, then add new items
    if delta > 0
      _.times delta, =>
        newMatrixItem = angular.copy matrixItem
        @items.push newMatrixItem

     # Only keep the items needed
    if delta < 0
      @items = @items.slice(0,@numItems)

  @setTags = =>
    @tags = @tagsInput.split(',')

  @initializeTagInput = =>
    @tagsInput = @tags.join(',')

  @toggleItemTag = (itemTags, tag) ->
    itemTags[tag] = ! itemTags[tag]

  @initializeTagInput()

  this

@app.directive 'matrixFilterForm', (ComponentService) ->
  restrict: 'E'
  scope: {}
  controller: 'matrixFilterFormController'
  controllerAs: 'matrixFilterForm'

  bindToController: {
    title: '='
    items: '='
    rows: '='
    columns: '='
    itemType: '='
    theme: '='
    descriptionCharLimit: '='
    tags: '='
    includeAll: '='
    visibleRows: '='
  }

  templateUrl: "/assets/templates/components/matrixFilter/matrixFilterForm.html"
