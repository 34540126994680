@app.directive 'pageNavJouleSpree', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'pageNavJouleSpree'
  controller:($state, $element, $scope, $location, csConfig) ->

    @spreeEndpoint = "#{csConfig.spreeEndpoint}"
    @joulePage = false
    @appPage = false

    if $location.path() == '/joule'
      @joulePage = true
      @appPage = false

    if $location.path() == '/joule/app'
      @appPage = true
      @joulePage = false

    if $state.locationData.country == 'US'
      @showAccessories = true
    else if $state.locationData.country == 'CA'
      @showBigClamp = true
    else
      @showAccessories = false
      @showBigClamp = false

    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/pageNav/pageNavJouleSpree/pageNavJouleSpree.html"
