@app.directive 'matrixItemMedia', (matrixItemService, utilities) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isRecipe = matrixItemService.isRecipe

    @content.description = utilities.customSanitize(@content.description, true)
    @content.title = utilities.customSanitize(@content.title)
    this
  controllerAs: 'matrixItemMedia'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemMedia/matrixItemMedia.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    resourceType: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    track: '&'
    theme: '='
  }
