@app.directive 'csEmbedBrightcove', ($sce, $timeout, $state)->
  restrict: 'E'
  replace: false
  scope: {}
  controllerAs: 'csEmbedBrightcove'
  bindToController:
    playerId: '@'
    videoId: '@'
    tabIndex: '@'
    stepOrder: '@'
    background: '@'
    showOverlay: '@'
    autoplay: '@'
    initializeOverlay: '='

  controller: ($scope) ->
    $scope.currentUser = $state.currentUser
    this

  link: (scope) ->
    playerData = {
      accountId: "6260977927001",
      playerId: if _.isEmpty(scope.csEmbedBrightcove.playerId) then "default" else scope.csEmbedBrightcove.playerId,
      videoId: scope.csEmbedBrightcove.videoId
    }

    playerHTML =
      '<video-js  data-video-id="' +
        playerData.videoId +
        '"  data-account="' +
        playerData.accountId +
        '"  id="' +
        scope.csEmbedBrightcove.stepOrder + "-" + playerData.playerId +
        '" data-player="' +
        playerData.playerId +
        '" data-embed="default" class="video-js" controls></video-js>'

    createBrightCovePlayer = ->
      document.getElementById("brightcoveHolder-" + scope.csEmbedBrightcove.stepOrder).innerHTML = playerHTML
      playerScriptElement = document.createElement("script")
      playerScriptElement.src = "https://players.brightcove.net/" + playerData.accountId + "/" + playerData.playerId + "_default/index.min.js"
      document.body.appendChild(playerScriptElement)
      playerScriptElement.onload = ->
        canAutoplay() if scope.csEmbedBrightcove.autoplay == "1"
        scope.csEmbedBrightcove.initializeOverlay =  true if (!!! scope.currentUser) && scope.csEmbedBrightcove.showOverlay=="true"

    canAutoplay = ->
      player = bc(scope.csEmbedBrightcove.stepOrder + "-" + playerData.playerId)
      player.on("loadeddata", ->
        if (scope.csEmbedBrightcove.background == '1')
          player.muted(true)
          player.loop(true)
        player.play()
        scope.$apply()
      )


    $timeout (->
      createBrightCovePlayer()
    ), 300

  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedBrightcove/csEmbedBrightcove.html"