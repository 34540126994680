@app.directive 'mediaMultiForm',  ->
  restrict: 'E'
  scope: {}
  controllerAs: 'mediaMultiForm'
  controller: ($scope) ->

    @setNumItems = =>
      itemsLength = @content.items?.length || 0
      delta = @content.numItems - itemsLength
      item =
        image: ''
        youtubeId: ''
        vimeoId: ''

      # If there are not enough items, then add new items
      if delta > 0
        _.times delta, =>
          newItem = angular.copy item
          @content.items.push newItem

       # Only keep the items needed
      if delta < 0
        @content.items = @content.items.slice(0,@content.numItems)

    unless @content.items?
      @content.items = []
      @setNumItems()
    this

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/media/mediaMultiForm.html"
