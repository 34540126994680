@app.directive 'signup', (ModalService, LocationService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()
    @shouldSendToFTUE = ModalService.shouldSendToFTUE()

    @login = ->
      # Transfer params
      ModalService.open('login', ModalService.modal.params)

    @isInUS = ->
      LocationService.isInUS()

    this

  controllerAs: 'signup'
  templateUrl: "/assets/templates/modals/signUp/signUp.html"
