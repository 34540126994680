@app.directive 'matrixItemPopup', (ModalService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @openPopup = ->
      data =
        title: @content.title
        description: @content.description
        image: @content.image
      ModalService.open('content', {content: data})

    this
  controllerAs: 'matrixItemPopup'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemPopup/matrixItemPopup.html"
  bindToController: {
    content: '='
    loadImageOnEnterViewport: '='
    track: '&'
  }
