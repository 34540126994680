@app.directive 'manageSubscriptionButton', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'manageSubscriptionButton'
  controller: (subscriptionService) ->
    @onClick = ->
      subscriptionService.configure()
    
    this

  bindToController: {
    content: '='
  }

  templateUrl: '/assets/templates/components/customButton/manageSubscriptionButton/manageSubscriptionButton.html'
