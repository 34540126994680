@app.service 'DiscountService', ($resource, csConfig, localStorageService, $q) ->

  @resource = $resource "#{csConfig.chefstepsEndpoint}/api/v0/shopping/discounts/:id", { id: '@id' },
    'show':
      method: 'GET'
      isArray: false

  # Will go away one /butchers?discount_id is finished
  @get = (discountId) =>
    deferred = $q.defer()

    if @discount
      deferred.resolve @discount
    else
      loadDataSuccess = (data) =>
        @discount = data
        deferred.resolve @discount
      loadDataError = (error) ->
        Rollbar.error("DiscountService - error while loading discount, discountId: #{discountId}, error: #{JSON.stringify(error)}")
        deferred.reject error

      @resource.show({id: discountId}, loadDataSuccess, loadDataError)

    deferred.promise

  @setCode = (code) =>
    @discountCode = code

  this
