@app.directive 'header', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'header'
  controller: ->

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/header/header.html"
]
