@app.directive 'jobsForm', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'jobs'
  controller: ($scope) ->
    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/jobs/jobsForm.html"
