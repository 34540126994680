@app.directive 'heroForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'hero'
  controller: ->
    @heroTypeOptions = ['standard', 'collection']
    if !@content.heroType
      @content.heightType = 'standard'

    @heightTypeOptions = ['standard', 'short', 'tall']
    if !@content.heightType
      @content.heightType = 'standard'

    @alignTypeOptions = ['left', 'center', 'right']
    if !@content.alignType
      @content.alignType = 'center'

    @setItem = (selectedItem, currentItem)->
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]

    @content.buttonHide ||= false

    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/hero/heroForm.html"
]
