@app.directive 'signupButton', (ModalService, $state) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'signupButton'
  controller: ->

    @currentUser = $state.currentUser

    @handleClick = ->
      ModalService.open('signup', source: 'customButton signupButton')

    this
  bindToController: {
    signedInTitle: '='
    signedOutTitle: '='
    theme: '='
  }
  templateUrl: "/assets/templates/components/customButton/signupButton/signupButton.html"
