

@app.directive 'jobs', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'jobs'
  controller: ($http) ->
    @url = 'https://hire.withgoogle.com/v2/api/t/chefstepscom/public/jobs'
    $http.get @url
      .then (response) =>
        @departmentNames = _.uniq response.data.map (item) -> item.hiringOrganization.department.name
        @jobsByDepartment = @departmentNames.map (departmentName) ->
          {
            departmentName: departmentName
            jobs: response.data
              .filter (item) -> item.hiringOrganization.department.name == departmentName
              .map (item) ->
                {
                  title: item.title
                  url: item.url
                  departmentName: departmentName
                  # Sad that I have to do this below, but there is no short description in the feed and the description we have in the job is way too long
                  # Basically, its getting the first paragraph and removing some unwanted text
                  description: $(item.description).first().first().text().replace(':','').replace(/(WHAT YOU[\S]LL DO)/gi,'')
                }
          }

    this

  bindToController: {
    theme: '='
    content: '='
  }

  templateUrl: "/assets/templates/components/jobs/jobs.html"
