@app.directive 'modalConsent', (ModalService, LocationService, $http, $state)->
  restrict: 'E'
  scope: {},
  controller: ($scope) ->
    @showOptInConsent = true
    @optIn = true

    updateApi = (data) ->
      $http.post(
        "#{csConfig.chefstepsEndpoint}/api/v0/users/update_user_consent",
        { user: data }
      ).success((res) ->
        $state.currentUser.opt_in = _.get(res, 'opt_in', $state.currentUser.opt_in)
      )

    @afterInit = ->
      # @showOptInConsent will decide to show the consent with checkbox or not
      # @showOptInConsent is false then by default the user accepted the consent, Set opt_in true
      # else @showOptInConsent is true then the user needs to accept it manually
      unless @showOptInConsent
        param = { opt_in: true }
        updateApi(param)
        console.log(JSON.stringify(param))

    @updateOptIn = (optIn) ->
      if @showOptInConsent
        updateApi({opt_in: optIn})
      console.log("opted ? " + optIn)

      # ModalService.open('ftue')
      ModalService.resolve()

    this

  controllerAs: 'modalConsent'
  templateUrl: "/assets/templates/modals/modalConsent/modalConsent.html"
