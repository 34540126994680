@app.directive 'ssoForm', (ssoService, $location) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @csOnly = $location.search().csOnly
    @visibleForm = 'signup'
    if (ssoService.isZendesk() || ssoService.isFBMessenger() || ssoService.isAmazon())
      @visibleForm = 'login'

    requestedForm = $location.search().form
    if requestedForm? && requestedForm == 'login'
      @visibleForm = 'login'

    @login = =>
      @visibleForm = 'login'

    @signup = =>
      @visibleForm = 'signup'

    @recoverPassword = =>
      @visibleForm = 'recoverPassword'
    this

  controllerAs: 'ssoForm'
  templateUrl: "/assets/templates/directives/ssoForm/ssoForm.html"
