@app.service 'moneyFormatService', ->

  @moneyFormat = (price,currency,format) ->
    format = format || 'short'
    price = parseInt(price)
    money = '$' + price

    # US Currency Format
    if currency == 'USD'
      money = '$' + price

    # Canada Currency Format
    if currency == 'CAD'
      money = if format == 'long' then 'CAD $' + price else '$' + price

    # EU Currency Format
    if currency == 'EUR'
      money = price + ' €'

    # UK Currency Format
    if currency == 'GBP'
      money = '£' + price

    # Switzerland Currency Format
    if currency == 'CHF'
      money = if format == 'long' then 'CHF ' + price else price

    # Sweden/Norway/Denmark Currency Format
    if currency == 'SEK' || currency == 'NOK' || currency == 'DKK'
      money = if format == 'long' then 'KR ' + price else price

    return money

  this
