@app.controller 'ComponentNewController', ($scope, ComponentService, authenticationService, $state, $stateParams) ->

  @parentType = $stateParams.componentParentType
  @parentId = $stateParams.componentParentId
  @name = ''
  @type = ''
  @meta = ComponentService.new().meta
  @position = 0
  @isSchedule = false
  @scheduleStartDate = moment().startOf('day').toDate()
  @minStartDate = moment().startOf('day').toDate()
  @minEndDate = @minStartDate

  this
