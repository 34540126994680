@app.directive 'pageNavForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'pageNavForm'
  controller: ->
    @typeOptions = ['Joule', 'Joule Spree', 'About']

    @setItem = (selectedItem, currentItem)->
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]
    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/pageNav/pageNavForm.html"
]
