@app.controller 'SSOController', (currentUser, $location, ssoService, $scope) ->
  @state = $location.search().state
  @token = $location.search().token
  @showForm = false
  @ssoService = ssoService
  $scope.currentUser = currentUser

  if currentUser && !ssoService.isAmazon() && !@token
    ssoService.signAndRedirect(@state)

  else if @token # /sso?token=SOMETOKEN
    redirect = $location.search().path
    utm_params = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_term', 'utm_content', 'gclid']

    angular.forEach utm_params, (param_name) ->
      param_val = $location.search()[param_name]
      if param_val
        redirect = redirect + "&#{param_name}=#{param_val}"

    if currentUser
      ssoService.redirectOrRoot(redirect)
    else
      ssoService.signAndUpgradeToken(@token,redirect)

  else
    @showForm = true

  this
