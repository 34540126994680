# This is designed so that experiments can be set up by filling in the variations one at a time as they are found
# on the page rather than requiring an experiment level setup that has to be guaranteed to execute first. This is
# advantageous b/c it will work even if spread across multiple pages with multiple possible entry points, but it does
# require each variation site to have knowledge of the total number of variations in this experiment.

# Core service split out to avoid circular dependency between AnalyticsService & ExperimentService
@app.service 'ExperimentServiceCore', (localStorageService, $state) ->

  key = 'CS Experiments'
  experiments = {}

  @deserializeExperiments = ->
    experiments = localStorageService.get(key) || {}

  @serializeExperiments = ->
    localStorageService.set(key, experiments)

  @getExperiments = ->
    experiments

  @clearAllExperiments = =>
    experiments = {}
    @serializeExperiments()

  @getVariation = (experimentName) ->
    experiments[experimentName]

  @isInVariationInternal = (experimentName, variationName, variationCount) =>
    # Haven't seen this experiment before? Lazily create the experiment and choose a variation for this user.
    # It might not be the one we are asking about right now!
    if ! experiments[experimentName]
      choice = parseInt(Math.random() * variationCount)
      experiments[experimentName] = {variationIndex: choice}
      @serializeExperiments()

    # Find or lazily assign variationIndex for this variationName in this experiment
    experiments[experimentName].variations = experiments[experimentName].variations || []
    variationIndex = _.indexOf(experiments[experimentName].variations, variationName)
    if variationIndex == -1
      experiments[experimentName].variations.push(variationName)
      variationIndex = experiments[experimentName].variations.length - 1
      @serializeExperiments()

    # Determine if the variationIndex chosen for this user is the one being queried about.
    inVariation = parseInt(experiments[experimentName].variationIndex) == parseInt(variationIndex)

    # Lazily store off variationName for the chosen variation when we eventually query about it.
    # This is only used for AnalyticsService call below.
    if inVariation && ! experiments[experimentName].variationName
      experiments[experimentName].variationName = variationName
      @serializeExperiments()

    inVariation

  # Initialization
  @deserializeExperiments()

  this


@app.service 'ExperimentService', ($rootScope, ExperimentServiceCore, AnalyticsService, utilities, $location, $state) ->

  tracked = {}

  service = angular.extend {}, ExperimentServiceCore,
    isInVariation: (experimentName, variationName, variationCount) ->
      # Allow URL param like http://localhost:3000/?Home%20Hero=Control to force a particular view for admin convenience
      if $location.search()[experimentName]
        return $location.search()[experimentName] == variationName

      # Special case - if the variationCount is 1, we assume that it is really 2 but the other one is empty - not showing any content.
      # See if we are assigned to the empty group and track that if so.
      if variationCount == 1
        variationCount = 2
        inEmptyVariation = service.isInVariationInternal(experimentName, 'empty', variationCount)
        service.trackExperiment(experimentName) if inEmptyVariation

      inVariation = service.isInVariationInternal(experimentName, variationName, variationCount)
      service.trackExperiment(experimentName) if inVariation

      inVariation

    trackExperiment: (experimentName) ->
      return if tracked[experimentName]

      tracked[experimentName] = true
      variationName = service.getVariation(experimentName).variationName

      # Using schema from https://segment.com/docs/spec/ab-testing/
      AnalyticsService.track 'Experiment Viewed',
        experiment_id: utilities.stringToIdentifier(experimentName)
        experiment_name: experimentName
        variation_id: utilities.stringToIdentifier(variationName)
        variation_name: variationName

    init: ->
      # For URL-level experiments, pick a variation for each and update the global state array
      # with the chosen variation options.
      for stateKey, state of $state.get()
        if state.experiment?
          variationCount = Object.keys(state.experiment.variations).length

          for variationName, variationData of state.experiment.variations
            # Use isInVariationInternal so we don't track an Experiment Viewed now
            if service.isInVariationInternal(state.experiment.name, variationName, variationCount)

              # Store the updated state back into the global table
              angular.merge(state, variationData)

      # For URL-level experiments, track when actually visiting the state
      $rootScope.$on '$stateChangeStart', (event, state) ->
        if state.experiment?
          service.trackExperiment(state.experiment.name)

  return service
