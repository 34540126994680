@app.directive 'priceTag', ($state, matrixItemService, utilities) ->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->

    @isPurchased = =>
      _.includes(_.get($state.currentUser, 'purchased_classes', []), @content.slug)
    @hasStudioPass = _.get($state.currentUser, 'studio', false)
    @hasPremium = _.get($state.currentUser, 'premium', false)
    purchased = _.includes(_.get($state.currentUser, 'purchased_classes', []), @content.slug)
    @subscribed = @hasStudioPass || @hasPremium
    @canPurchase = !@isPurchased() && !@subscribed && !_.isEmpty(@content.price) && @isLabel != true
    @buttonTheme = {dark: 'outline light', gray: 'dark', light: 'dark', orange: 'outline light'}[@theme]
    @buttonConf = {type:'subscription', title: @content.price || 'Click here to purchase', theme: @buttonTheme, planId: @content.plan, isOrder: true, referrer: @content.slug}
    this
  controllerAs: 'priceTag'
  templateUrl: "/assets/templates/components/priceTag/priceTag.html"
  bindToController: {
    content: '='
    theme: '='
    isLabel: '='
  }
