@app.service 'ProductGroupsService', ($q, LocationService, $http) ->

  @loadData = (location) =>
    @location = location
    deferred = $q.defer()

    if @products
      deferred.resolve @products
    else
      loadDataSuccess = (data) =>
        @products = data
        deferred.resolve @products
      loadDataError = (error) ->
        Rollbar.error("ProductGroupsService - error while calling loadData at url: #{$state.current.url}, error: #{JSON.stringify(error)}")
        if error.status == 403
          $state.go '/logout'
        deferred.reject error

      $http.get("#{csConfig.chefstepsEndpoint}/api/v0/shopping/product_groups?iso2=#{location.country}").then(loadDataSuccess, loadDataError)

    deferred.promise

  @getProducts = =>
    @products

  this
