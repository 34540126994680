@app.controller 'JouleLandingLastHeroController', ($element, $rootScope, LocationService, responsiveService, $window, mobileDetectionService, AnalyticsService) ->

  # Set Location
  @isInUS = LocationService.isInUS()

  # Responsive Variables
  @isViewportMobile = responsiveService.isViewportMobile()
  @isMobileDevice = mobileDetectionService.isMobile
  @responsiveWidth = responsiveService
  angular.element($window).bind 'resize', =>
    @isViewportMobile = responsiveService.isViewportMobile()
    $rootScope.$safeApply()

  # track
  @scrollTrackLast = ->
    AnalyticsService.track 'Joule - Last Hero Seen'

  @buttonTrackLast = ->
    AnalyticsService.track 'Buy Button Clicked - Last Hero'

  this
