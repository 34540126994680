@app.directive 'matrixItemCardForm', (AnalyticsService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @setItem = (selectedItem, currentItem)->
      console.log 'setting item'
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]
    this
  controllerAs: 'matrixItemCardForm'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemCard/matrixItemCardForm.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    source: '@'
  }
