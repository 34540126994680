@app.directive 'csNellPopup', ->
  restrict: 'E'
  scope: {}
  bindToController:
    'isActive': '='
    'content': '='
    'createdByAdmin': '='
  controllerAs: 'csNellPopup'
  controller: ($scope, $http, ActivityService) ->
    @handleClickClose = ->
      $scope.$emit 'hideNellPopup'

    getRecipe = (slug) =>
      activityRequest = ActivityService.get id: slug, embedded: 'true'
      activityRequest.$promise
        .then (res) =>
          @description = res.description
          @imageUrl = res.image
          @title = res.title
          @heading = 'CHEFSTEPS RECIPE'
          @primaryButtonText = 'See the Recipe'
          @linkUrl = res.url
          @exception = false
          @isLoaded = true
        .catch =>
          @exception = true
          @exceptionMsg = 'Recipe is not available'
          @isLoaded = true

    getIngredient = (id) =>
      url = "#{csConfig.chefstepsEndpoint}/api/v0/ingredients/#{id}"
      $http.get(url)
        .then (res) =>
          # set desc and image
          @description = res.data.textFields?.description
          @imageUrl = res.data.image
          @title = res.data.title
          @linkUrl = res.data.url
          @productUrl = res.data.productUrl
          @heading = 'CHEFSTEPS INGREDIENT WIKI'
          @primaryButtonText = 'Learn More'
          @exception = false
          @isLoaded = true
        .catch =>
          @exception = true
          @exceptionMsg = 'Ingredient is not available'
          @isLoaded = true

    $scope.$watch (=> @content), =>
      @isLoaded = false
      @imageUrl = null
      @title = null
      @description = null
      if @content?.type == 'ingredient'
        if @content.data.quantity?.unit == 'recipe' || @content.data.subActivity
          getRecipe _.last @content.data.url?.split('/')
        else
          getIngredient @content.data.id
    this
  templateUrl: "/assets/templates/directives/nell/csNellPopup/csNellPopup.html"
