@app.directive 'multiSubscriptionButtonForm', ($state, $filter)->
  restrict: 'E'
  scope: {}
  controllerAs: 'multiSubscriptionButtonForm'
  controller: ($scope) ->

    @toolbar = [
      ['cs_h1', 'cs_h2', 'cs_h3', 'cs_h4', 'cs_h5', 'cs_h6', 'cs_p']
      ['bold','italics']
      ['csAlignLeft', 'csAlignCenter', 'csAlignRight', 'csAlignFull']
      ['html']
    ]

    # When the content changes, add the align-left default to any <p>
    $scope.$watch 'multiSubscriptionButtonForm.content', (newValue) =>
      @content.description = $filter('paragraphAlignLeft')(newValue.description)

    # Paste text only
    @onPaste = (html) ->
      stripped = String(html).replace(/<[^>]+>/gm, '')
      stripped

    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/customButton/multiSubscriptionButton/multiSubscriptionButtonForm.html"
