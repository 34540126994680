@app.directive 'buyResult', (ModalService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()

    @close = ->
      ModalService.resolve()

    this

  controllerAs: 'buyResult'
  templateUrl: "/assets/templates/modals/buyResult/buyResult.html"
