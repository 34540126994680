@app.controller 'jouleHighlights', ($window, AnalyticsService) ->

  @mediaContent1 =
    image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/MJbOMMvgTyK3ltCwB2Ja'
    youtubeId: 'QBjjIWMYH8s'

  @mediaContent2 =
    image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/Bb5KdIDR2HTQnAadvHw1'

  @mediaContent3 =
    image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/BRAQBY1uRYqb5bZLrBjj'

  @buttonTrack = ->
    AnalyticsService.track 'Buy Button Clicked - Small Messaging'

  @videoTrack = ->
    AnalyticsService.track 'Video Played - See Joule In Action'

  @iosTrack = ->
    AnalyticsService.track 'Button Clicked - iOS App Link'

  @androidTrack = ->
    AnalyticsService.track 'Button Clicked - Android App Link'

  this
