@app.directive 'spreeButton', ($state, moneyFormatService) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    label: '='
    theme: '='
    sku: '='
    showPrice: '='
    showQuantity: '='
    format: '='
  }
  controllerAs: 'spreeButton'
  controller: ->
    @productGroupsData = $state.productGroupsData
    @productsMap = _.map @productGroupsData.product_groups, (productGroup) -> productGroup.products
    @products = _.reduceRight @productsMap, (a,b) -> a.concat(b)
    @product = _.find @products, (product) => product.sku == @sku

    # Create money format function using the Money Format Service
    @currency = @productGroupsData.currency
    @moneyFormat = (price) =>
      moneyFormatService.moneyFormat(price, @currency, @format)

    if @product
      @inStock = @product.orderable
      @buttonPrice = @moneyFormat(@product.price)
      @comparePrice = undefined

      if @product.compare_to_price > @product.price
        @comparePrice = @moneyFormat(@product.compare_to_price)

      if @inStock
        @url = "#{csConfig.spreeEndpoint}/cart/add?skus=#{@product.sku}"

    this
  templateUrl: '/assets/templates/components/customButton/spreeButton/spreeButton.html'
