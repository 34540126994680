# Factory that maps to api v0 components endpoint
@app.factory 'ComponentService', ($resource, csConfig, localStorageService) ->
  userToken = localStorage.getItem('access_token')
  headerParams = { 'Authorization': 'Bearer ' + userToken, "x-csrf-token":undefined }
  Component = $resource "#{csConfig.chefstepsEndpoint}/api/v0/components/:id", { id: '@id' },
    'create':
      method: 'POST'
      headers: headerParams
    'index':
      method: 'GET'
      isArray: true
      headers: headerParams
    'show':
      method: 'GET'
      isArray: false
    'update':
      method: 'PUT'
      headers: headerParams
    'destroy':
      method: 'DELETE'
      headers: headerParams

  defaultCharLimit = 200

  Component.new = (properties = {}) ->
    component =
      meta:
        items: [
          content: {}
        ]

    switch properties.type
      when 'feed'
        component.meta.size = 'standard'
        component.meta.theme = 'light'
        component.meta.rows = 1
        component.meta.columns = 1
        component.meta.itemTypeName = 'Media'
        component.meta.descriptionCharLimit = defaultCharLimit

      when 'fifty-fifty'
        component.meta.size = 'full'
        component.meta.theme = 'light'

      when 'header'
        component.meta.size = 'full'
        component.meta.theme = 'light'

      when 'hero'
        component.meta.size = 'full'
        component.meta.theme = 'light'

      when 'matrix'
        component.meta.size = 'standard'
        component.meta.theme = 'light'
        component.meta.rows = 1
        component.meta.columns = 1
        component.meta.itemTypeName = 'Media'
        component.meta.descriptionCharLimit = defaultCharLimit

      when 'matrix-collapsible'
        component.meta.size = 'standard'
        component.meta.theme = 'light'
        component.meta.rows = 1
        component.meta.columns = 1
        component.meta.showItemNumbers = false
        component.meta.itemTypeName = 'List'
        component.meta.descriptionCharLimit = defaultCharLimit

      when 'matrix-filter'
        component.meta.size = 'standard'
        component.meta.theme = 'light'
        component.meta.rows = 1
        component.meta.columns = 1
        component.meta.itemTypeName = 'Media'
        component.meta.descriptionCharLimit = defaultCharLimit
        component.meta.tags = []

      when 'media'
        component.meta.size = 'standard'
        component.meta.theme = 'light'

      when 'comments'
        component.meta.size = 'standard'
        component.meta.theme = 'light'

    component

  itemStructs =

    'fifty-fifty': {
      content:
        title: ''
        description: ''
        image: ''
        buttonMessage: 'See the Recipe'
        url: ''
        descriptionCharLimit: defaultCharLimit
    }

    'header': {
      content:
        title: 'Title'
        subtitle: 'Subtitle'
    }

    'hero': {
      content:
        title: ''
        description: ''
        image: ''
        buttonMessage: 'See the Recipe'
        url: ''
        descriptionCharLimit: defaultCharLimit
        heightType: 'standard'
        heroType: 'standard'
    }

    'matrix': {
      content:
        title: ''
        description: ''
        image: ''
        buttonMessage: 'See the Recipe'
        url: ''
        descriptionCharLimit: defaultCharLimit
        activity_type: []
        premium: false
        studio: false
    }

    'matrix-collapsible': {
      content:
        title: ''
        description: ''
        image: ''
        buttonMessage: 'See the Recipe'
        url: ''
        descriptionCharLimit: defaultCharLimit
        activity_type: []
        premium: false
        studio: false
    }

    'matrix-filter': {
      content:
        title: ''
        description: ''
        image: ''
        buttonMessage: 'See the Recipe'
        url: ''
        descriptionCharLimit: defaultCharLimit
        tags: {}
    }

    'media': {
      content:
        title: ''
        description: ''
        image: ''
        url: ''
        viewerType: ''
        numItems: 1
        items: []
    }

  Component.newItem = (type) ->
    itemStructs[type]

  return Component
