@app.directive 'claimSubscriptionGiftForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'claimSubscriptionGiftForm'
  controller: ->
    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/claimSubscriptionGift/claimSubscriptionGiftForm.html"
]
