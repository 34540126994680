@app.directive 'timeTempGuide', (TimeTempService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'timeTempGuide'
  controller: ->
    @basicItems = TimeTempService.basicItems
    @beefSteakItems = TimeTempService.beefSteakItems
    @beefRoastItems = TimeTempService.beefRoastItems
    @beefToughItems = TimeTempService.beefToughItems
    @porkChopItems = TimeTempService.porkChopItems
    @porkRoastItems = TimeTempService.porkRoastItems
    @porkToughItems = TimeTempService.porkToughItems
    @chickenLightItems = TimeTempService.chickenLightItems
    @chickenDarkItems = TimeTempService.chickenDarkItems
    @fishItems = TimeTempService.fishItems
    @vegetableItems = TimeTempService.vegetableItems
    @fruitItems = TimeTempService.fruitItems
    this

  bindToController: {

  }
  templateUrl: "/assets/templates/directives/timeTempGuide/timeTempGuide.html"
