@app.directive 'intlDropdown', ($rootScope, $state, InternationalService, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'dropdown'
  controller: ($scope) ->
    @internationalData = $state.internationalData
    @locationData = $state.locationData
    @currentCountry = _.find @internationalData.countries, (country) =>
      country.code == @locationData.country
    @dropdownHiddenInNav = false

    # If a country is not supported, default to US
    if !@currentCountry
      @currentCountry = _.find @internationalData.countries, (country) ->
        country.code == 'US'
    @showDropdown = false
    @isMobile = mobileDetectionService.detect()

    @toggle = =>
      @showDropdown = !@showDropdown

    @flagIconClass = (country) ->
      if country
        'flag-icon-' + country.toLowerCase()

    @selectCountry = (country) =>
      InternationalService.setCountry(country.code)
        .then (response) =>
          @currentCountry = country
          @showDropdown = false
          window.location.reload()
    this

  templateUrl: "/assets/templates/directives/intlDropdown/intlDropdown.html"
