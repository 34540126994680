@app.directive 'commentsForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'commentsForm'
  controller: ->

    this
  bindToController: {
    discussionId: '='
    commentsTitle: '='
    description: '='
  }

  templateUrl: "/assets/templates/components/comments/commentsForm.html"
]
