@app.directive 'csIcon', ->
  restrict: 'A'
  scope:
    csIcon: '@'
  link: (scope, element, attrs) ->
    scope.$watch 'csIcon', -> scope.csIconId = '#' + scope.csIcon
  template:
    '''
      <svg class="cs-icon-{{csIcon}}-dims">
        <use xlink:href={{csIconId}}></use>
      </svg>
    '''