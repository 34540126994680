@app.controller 'addressConfirmedController', ($stateParams, addressConfirmed) ->

  status = addressConfirmed.status

  @orderId = $stateParams.id
  if status == 200
    @success = true
  else
    @success = false
  this
