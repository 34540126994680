@app.filter "wcagSanitize", ->
  (input) ->
    if input
      conf = {
        "<b>": "<span class='strong'>",
        "<i>": "<span class='italic'>",
        "</b>": "</span>",
        "</i>": "</span>",
        "<p>": "<div class='text-content'>"
        "</p>": "</div>"
      }
      _.each(conf, (value, key, _obj) ->  input = input.replace(new RegExp(key, 'g'), value) )
      return input
    else
      ""
