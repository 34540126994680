@app.directive 'socialButtons', ->
  restrict: 'E'
  replace: true
  scope: {}
  bindToController:
    socialTitle: '@'
    socialImage: '@'
    commentCount: '@'
    buttonTheme: '@'
  controllerAs: 'socialButtons'
  controller: ($location, csFilepickerMethods, AnalyticsService) ->
    socialUrl = "#{$location.protocol()}://#{$location.host()}#{$location.path()}"

    tweetMessage = 'I love this:'

    socialImageConverted = =>
      csFilepickerMethods.convert @socialImage, width: 800

    emailSubject = =>
      "I thought you might like #{@socialTitle}"

    emailBody = =>
      "Hey, I thought you might like #{@socialTitle} at ChefSteps.com. Here's the link: #{socialUrl}"

    track = (socialNetwork) ->
      shareCat = socialUrl.split("/")[3]
      AnalyticsService.track 'Share', Network: socialNetwork, URL: socialUrl, ShareCat: shareCat

    openSocialWindow = (socialNetwork, url, spec) ->
      window.open url, "_blank", spec || "width=500, height=300, top=100, left=100"
      track socialNetwork

    @shareTwitter = =>
      openSocialWindow 'Twitter', "https://twitter.com/intent/tweet?text=" + tweetMessage + " " + @socialTitle + " @ChefSteps&url=" + window.escape(socialUrl)

    @shareFacebook = ->
      openSocialWindow 'Facebook', "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(socialUrl), 'width=626,height=436,top=100,left=100'

    @sharePinterest = =>
      openSocialWindow 'Pinterest', "https://pinterest.com/pin/create/button/?url=" + encodeURIComponent(socialUrl) + "&media=" + encodeURIComponent(@socialImage) + "&description=" + encodeURIComponent(@socialTitle), 'width=300,height=600,top=100,left=100'

    @shareEmail = ->
      openSocialWindow 'Email', "mailto:?subject="+ encodeURIComponent(emailSubject()) + "&body=" + encodeURIComponent(emailBody())

    @shareEmbedly = ->
      #TODO: use analytics to track this
      embedly 'modal',
        url: socialUrl + "?utm_source=embedly"
      track 'Embedly'

    @buttonTheme = @buttonTheme || 'outline'
      
    this

  templateUrl: "/assets/templates/directives/socialButtons/socialButtons.html"
