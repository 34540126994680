@app.directive 'modals', ($rootScope, ModalService, $timeout) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @params = ModalService.params()

    @close = ->
      ModalService.reject()
    this
  controllerAs: 'modals'
  bindToController: {
    showModals: '='
    activeModal: '='
    modalParams: '='
  }
  link: (scope, element, attrs) ->

    element.on 'click', (event) ->
      if angular.element(event.target).attr('close-on-click')
        $rootScope.$safeApply ModalService.reject
        return

    scope.$watch 'modals.activeModal', (newValue, oldValue) ->
      if newValue
        element.addClass "modal-container-#{newValue}"
      else
        $timeout(->
          element.removeClass "modal-container-#{oldValue}"
        ,500)

  templateUrl: "/assets/templates/modals/modals.html"
