@app.service 'matrixItemService', ($state)->

  @isRecipe = (activityType) ->
    _.includes(activityType, 'Recipe')

  @isClasses = (content) ->
    content.source == 'Classes'

  @showClassTag = (content) =>
    unless @isClasses(content)
      return false

    _.isEmpty($state.currentUser) || ($state.currentUser && (!content.studio || (content.studio && !@isPurchased(content))))

  @isPurchased = (content) =>
    if @isClasses(content) && (_.includes(_.get($state?.currentUser, 'purchased_classes', []), content.slug))
      return true
    else
      return false

  @subscriptionLevel = (content) =>
    if @isClasses(content)
      @classesSubLevel(content)
    else
      @activitySubLevel(content)

  @activitySubLevel = (content) ->
    if content.studio
      'Studio Pass'
    else if content.premium
      'Premium'

  @classesSubLevel = (content) =>
    if content.studio && !@isPurchased(content)
      if !_.isEmpty($state.currentUser) && $state.currentUser.premium && !$state.currentUser.studio
        'Premium'
      else
        'Studio Pass'

  this
