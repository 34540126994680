@app.directive 'subscriptionButtonForm', ($state)->
  restrict: 'E'
  scope: {}
  controllerAs: 'subscriptionButtonForm'
  controller: ->
    this

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/subscriptionButton/subscriptionButtonForm.html"
