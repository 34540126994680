@app.directive 'login', (ModalService, AnalyticsService, $state, authenticationService, $http, $rootScope, csAdwords, csFacebookConversion, formHelper, localStorageService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()
    @showErrorFor = (fieldName, validationParam) =>
      formHelper.showErrorFor(@form, fieldName, validationParam)

    @close = ->
      ModalService.reject()

    @submit = =>

      loginSuccess = =>
        @showSpinner = false

        # Mixpanel
        properties = ModalService.modal.params
        AnalyticsService.reidentify()
        AnalyticsService.track 'Logged In JS', properties
        @resolveSuccess()

      loginError = =>
        @errorMessage = 'We were unable to match the email and password you provided. Please check your entries and try again.'
        @showSpinner = false

      if @form.$valid
        @showSpinner = true

        authenticationService
          .loginWithEmail @form.email.$modelValue, @form.password.$modelValue
          .then loginSuccess, loginError

    @signup = ->
      # Transfer params
      ModalService.open('signup', ModalService.modal.params)

    @recover = ->
      ModalService.open('recover')

    @resolveSuccess = (type='normal') ->
      # Switched away from the reloadAndResolve and instead do our own so that we can
      # show the special terms after login.  This is requested from the lawyers
      $rootScope.$on '$stateChangeSuccess', ->
        if $state.currentUser?.needs_special_terms
          userToken = localStorage.getItem('access_token')
          $http(
            method: 'GET'
            url: '/api/v0/users/shown_terms'
            headers:
              Authorization: "Bearer #{userToken}"
          )
          ModalService.open('specialTerms')
        else
          ModalService.resolve()
      $state.reload()

    # The following is taken originally from playground
    # The intention is for this to move to cs-shared
    @facebook = =>

      @showSpinner = true

      loginSuccess = (response) =>
        @showSpinner = false
        if response.newUser
          # This is questionable - if they are logging in via facebook instead
          # of signing up via facebook, should we really be tracking a conversion?
          # I guess the issue is that, as of now we don't really know whether
          # they were already in the DB - we could pass that back from the server.
          csAdwords.track(998032928,'77TfCIjjrAgQoIzz2wM')
          csFacebookConversion.track(6014798030226,0.00)

          # Mixpanel
          properties = {source : 'modal', method: 'facebook'}
          AnalyticsService.reidentify()
          AnalyticsService.track 'Signed Up JS', properties

          # Google Analytics
          AnalyticsService.track 'Complete', { category: 'Sign Up' }
          @resolveSuccess('ftue')
        else
          @resolveSuccess()

      loginError = (error) =>
        @showSpinner = false
        if error.message == 'User cancelled login or did not fully authorize.'
          @errorMessage = "Please try again. Click 'okay' to allow ChefSteps to sign in with Facebook"

        # TODO Handle situation where a ChefSteps account tries to sign in with Facebook
        # if error.status == 401
        #   ModalService.open('requirePassword', {email: error.user.email})

      facebookResponse = window.facebookResponse
      authenticationService
        .loginWithFacebook(facebookResponse)
        .then loginSuccess, loginError

    this

  controllerAs: 'login'
  templateUrl: "/assets/templates/modals/login/login.html"
