@app.service 'ssoService', ($http, $q, localStorageService, $location)->

  @complete = false

  @isZendesk = ->
    rt = $location.search().return_to
    rt? && (rt.indexOf('zendesk') != -1 || rt.indexOf('support.chefsteps.com') != -1)

  @isFBMessenger = ->
    rt = $location.search().redirect_uri
    rt? && (rt.indexOf('messenger.com') != -1)

  @isAmazon = ->
    rt = $location.search().path
    rt? && (rt.indexOf('amazon') != -1 || rt.indexOf('pitangui.amazon.com') != -1)

  @close = ->
    # This will work in most browsers
    try window.close() catch

    # This apparently works in some browsers
    # http://stackoverflow.com/questions/2076299/how-to-close-current-tab-in-a-browser-window
    try window.open('','_self').close() catch

    # Facebook messenger - https://developers.facebook.com/docs/messenger-platform/messenger-extension
    try MessengerExtensions.requestCloseBrowser((->), (->)) catch

  @signAndRedirect = (state) ->
    deferred = $q.defer()
    userToken = localStorage.getItem('access_token')

    config =
      params:
        # Shopify and Alexa use 'path', zendesk 'return_to', messenger 'redirect_uri'
        path: $location.search().path || $location.search().return_to ||  $location.search().redirect_uri || csConfig.chefstepsEndpoint
        state: state
      headers: { 'Authorization': 'Bearer ' + userToken }

    $http.get("#{csConfig.chefstepsEndpoint}/api/v0/auth/external_redirect", config).then ((response) =>
      # Typical case, send browser back to where the user needed to sign in
      @complete = true
      deferred.resolve()
      window.location = response.data.redirect

    ), (error) ->
      deferred.reject('There was an error while processing your request.')

    deferred.promise

  @signAndUpgradeToken = (token,redirect) =>
    deferred = $q.defer()

    config =
      headers: { 'Authorization': 'Bearer ' + token }

    # Authenticates with the short lived token
    $http.post("#{csConfig.chefstepsEndpoint}/api/v0/upgrade_token", {}, config).then ((response) =>
      upgraded_token = response.data.token
      # Successful upgrade of token will not be used
      localStorageService.set('user.token',upgraded_token)
      deferred.resolve()

      @redirectOrRoot(redirect)

    ), (error) ->
      deferred.reject('There was an error while processing your request.')

    deferred.promise

  @redirectOrRoot = (redirect_url) ->
    if redirect_url
      window.location = redirect_url
    else
      window.location = csConfig.chefstepsEndpoint

  this
