@app.directive 'jouleWaitlist', (ModalService, AnalyticsService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()

    @close = ->
      ModalService.resolve()

    $scope.formSubmitted = ->
      AnalyticsService.track 'Joule Waitlist Joined'
      true

    this

  controllerAs: 'jouleWaitlist'
  templateUrl: "/assets/templates/modals/jouleWaitlist/jouleWaitlist.html"
