@app.directive 'csOnEnterViewport', (staticRenderService, $window, $timeout) ->
  restrict: 'A'
  controllerAs: 'oevController'
  scope: {}
  bindToController:
    reachedScreenCallback: '&'
    offset: "="

  controller: ($scope, $element) ->

    @sentCallback = false
    windowElement = angular.element($window)

    check = =>
      return if @sentCallback || ! @reachedScreenCallback

      elementPosition = $element[0].getBoundingClientRect().top
      windowHeight    = $($window).height()
      offset          = parseInt(@offset || 100)

      # If this is a static render, or we've scrolled onto the page, send the callback
      if (staticRenderService.get() || (elementPosition < windowHeight - offset))

        # Use timeout to make sure this happens during a digest
        $timeout =>
          @reachedScreenCallback()
          @sentCallback = true

    # Check on scroll, content change (e.g. new search), resize, and initially after DOM is ready
    $scope.$on 'throttledScroll', check
    $scope.$on 'contentChange', check
    windowElement.on 'resize', check
    $timeout check

    # Clean up resize listener; the ones local scope are automatic
    $scope.$on '$destroy', ->
      windowElement.off 'resize', check

    this
