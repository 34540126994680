@app.directive 'spreeButtonForm', ($state, ProductGroupsService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'spreeButtonForm'
  controller: ->
    @moneyFormatOptions = ['long','short']
    this

  bindToController: {
    content: '='

  }
  templateUrl: "/assets/templates/components/customButton/spreeButton/spreeButtonForm.html"
