@app.controller 'JouleAppGuidesController', ($element, responsiveService) ->
  @guides = [
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/HEpKgGkaTTGWicJlqfk3'
      description: "What's for dinner?"
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/HLL0qXhS2uJtSlgWWlDa'
      description: "Pick the perfect meal."
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/DKkW3mtdTDewJKKhx9LM'
      description: "Learn new techniques with step-by-step instructions from ChefSteps."
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/qyLLVGRERVStfKpDMGBn'
      description: "How rare? How well done? How perfect."
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/IyCidpyrT0CkMV1Tyogs'
      description: "Dial in that timer."
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/VQENZoblT6KnDGAufmfx'
      description: "Prep the rest of your meal and just sit back and relax."
    }
    {
      image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/yOIkftQ965GbSm3KQftw'
      description: "We'll let you know when dinner's ready."
    }
  ]

  @currentIndex = 0

  if responsiveService.isViewportLTE(500)
    guideWidth = 220
  else
    guideWidth = 350

  guidesCount = @guides.length
  guidesElement = $($element).find('.joule-app-guides')

  @prev = =>
    if @currentIndex > 0
      @currentIndex -= 1
      guidesElement.css('transform', "translateX(-#{@currentIndex*guideWidth}px)")
      return

  @next = ->
    if @currentIndex < guidesCount - 1
      @currentIndex += 1
      guidesElement.css('transform', "translateX(-#{@currentIndex*guideWidth}px)")
      return

  @currentClass = (index) =>
    if @currentIndex == index
      'active'

  @firstItem = =>
    if @currentIndex == 0
      'hide-controls'

  @lastItem = =>
    if @currentIndex == guidesCount - 1
      'hide-controls'

  this
