@app.directive 'commentsList', (responsiveService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'commentsList'
  controller: ->
    if responsiveService.isViewportLTE(320)
      maxIndentations = 2
    else
      maxIndentations = 3
    @indentClass = =>
      if 0 < @depth < maxIndentations
        'comment-indent'
    this

  link: (scope, element, attrs) ->

  bindToController: {
    comments: '='
    depth: '='
  }
  templateUrl: "/assets/templates/components/comments/commentsList/commentsList.html"
