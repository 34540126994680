# TODO: replace any bloom related code with third party comment system, e.g. Disqus

@app.directive 'csComments', ($compile, $rootScope, csBloomManager) ->
  restrict: 'E'
  scope: {
    commentsType: '@'
    commentsId: '@'
    discussionId: '@'
    seoBot: '='
  }
  controller: ($scope, $http, $sce) ->
    $scope.renderSeoComments = ->
      $scope.seoComments = []
      identifier = $scope.commentsType + '_' + $scope.commentsId

      csBloomManager.loadBloom().then ->
        $http.get("#{csConfig.bloomApiEndpoint}/discussions/#{identifier}?apiKey=xchefsteps").then (response) ->
          comments = response.data.comments

          angular.forEach comments, (comment) ->
            $scope.seoComments.push($sce.trustAsHtml(comment.content))


    # TODO: this is not being called, the login button in bloom isn't functioning correctly
    # (see below), revisit this when bloom login button is fixed, $rootScope.$safeApply() seems uneccesary
    # this should jive with whatever modal implementation we are using
    $scope.openLogin = ->
      $scope.$emit 'openLoginModal'
      $rootScope.$safeApply()

  link: (scope, element, attrs) ->

    installComments = (discussionId) ->
      if scope.seoBot == true
        scope.renderSeoComments()
      else
        # Hack so that it doesn't install multiple iframes
        iframe = element[0].getElementsByTagName('iframe')

        if iframe.length > 0
          angular.forEach iframe, (frame) ->
            frame.remove()
        csBloomManager.loadBloom().then ->
          Bloom.installComments {
            el: element[0]
            discussionId: discussionId
            on:
              login: ->
                # TODO: this is broken, not being called
                # when you click the "Log in to join the discussion" button
                # @hueezer is looking into this
                scope.openLogin()
          }
    scope.$watch 'commentsId', (newValue, oldValue) ->
      if newValue
        identifier = scope.commentsType + '_' + scope.commentsId
        installComments(identifier)

    scope.$watch 'discussionId', (newValue, oldValue) ->
      if newValue
        installComments(scope.discussionId)

    # TODO: 'reloadComments' was emitted in chefsteps upon succesful login
    # reloading the page seems uneccesary... re-evaluate this when @hueezer has
    # implemented central auth caching
    $rootScope.$on 'reloadComments', (event) ->
      window.location.reload()

  template: "<div class='seo-comment' ng-repeat='c in seoComments' ng-bind-html='c'></div>"
