@app.directive 'jouleSelectorButton', ($state, $window, $rootScope, LocationService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'jouleSelectorButton'
  controller: ->


    targetOffset = undefined
    currentPosition = undefined
    body = document.body
    animateTime = 900

    getPageScroll = ->
      yScroll = undefined
      if window.pageYOffset
        yScroll = window.pageYOffset
      else if document.documentElement and document.documentElement.scrollTop
        yScroll = document.documentElement.scrollTop
      else if document.body
        yScroll = document.body.scrollTop
      yScroll

    @scrollToId = (target) ->
      targetOffset = document.getElementById(target).offsetTop
      currentPosition = getPageScroll()
      scrollLength = - (targetOffset - currentPosition)
      body.classList.add 'in-transition'
      body.style.WebkitTransform = 'translateY(' + scrollLength + 'px)'
      body.style.MozTransform = 'translateY(' + scrollLength + 'px)'
      body.style.transform = 'translateY(' + scrollLength + 'px)'
      window.setTimeout (->
        body.classList.remove 'in-transition'
        body.style.cssText = ''
        window.scrollTo 0, targetOffset
        return
      ), animateTime
      event.preventDefault()
      return

    @forceInUS = ->
      LocationService.forceInUS()

    # Set Location
    @isInUS = ->
      LocationService.isInUS()

    this

  templateUrl: "/assets/templates/directives/jouleSelectorButton/jouleSelectorButton.html"
