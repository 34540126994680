@app.directive 'pagePreview', (ModalService, $stateParams, $state, CookieService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @params = ModalService.params()
    @countries = $state.internationalData.countries
    @countryCode = 'US'
    if !_.isEmpty(CookieService.get('cs_geo'))
      cs_geo = JSON.parse(CookieService.get('cs_geo'))
      @countryCode = _.get(cs_geo, 'country', 'US')
    unless _.some(@countries, {'code': @countryCode})
      @countryCode = 'US'
    @isScheduled = @params.schedule_dates[0]
    @scheduleType = 'schedule'
    @previewBaseUrl = '/fs_pages/'+@params.pageId
    @previewUrl = @previewBaseUrl+"?country_code=#{@countryCode}"

    @flagIconClass = (country) ->
      return 'flag-icon-' + country.toLowerCase() if country

    @countryCodeUpdate = ->
      @previewUrl = @previewBaseUrl+"?country_code=#{@countryCode}"
      @previewUrl = @previewUrl + "&schedule_date=#{getDate(@scheduleDate)}" if @scheduleDate

    @scheduleDateUpdate = ->
      @previewUrl = @previewBaseUrl+"?country_code=#{@countryCode}&schedule_date=#{getDate(@scheduleDate)}"

    getDate = (date) ->
      moment(date).format("YYYY-MM-DD")

    this

  controllerAs: 'pagePreview'
  templateUrl: "/assets/templates/modals/pagePreview/pagePreview.html"
