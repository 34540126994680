@app.directive 'onScrolledTo', ($window, $rootScope, $timeout)->
  restrict: 'A'
  # scope: {}
  controllerAs: 'onScrolledTo'
  bindToController: {
    onScrolledTo: '&'
    inView: '&'
    notInView: '&'
  }
  controller: ->

    this
  link: (scope, element, attrs) ->

    scrolledTo = false
    windowHeight = $window.innerHeight
    loaded = false

    handleScroll = ->
      if loaded
        windowOffset = $window.pageYOffset
        elementOffset = element[0].offsetTop

        elementHeight = element[0].clientHeight
        elementTopOffset = elementOffset - windowHeight
        elementBottomOffset = elementOffset + elementHeight

        if elementTopOffset <= windowOffset <= elementBottomOffset
          if attrs['onScrolledTo'] && !scrolledTo
            scrolledTo = true
            if $rootScope.$$phase
              scope.$evalAsync attrs['onScrolledTo']
            else
              scope.$apply attrs['onScrolledTo']

          if attrs['inView']
            if $rootScope.$$phase
              scope.$evalAsync attrs['inView']
            else
              scope.$apply attrs['inView']
        else
          if attrs['notInView']
            if $rootScope.$$phase
              scope.$evalAsync attrs['notInView']
            else
              scope.$apply attrs['notInView']

    scope.$on 'throttledScroll', ->
      handleScroll()

    $timeout (->
      loaded = true
    ), 1000
