@app.directive 'customComponent', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'customComponent'
  controller: ->
    @templateUrl = "/assets" + @content.template
    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
    type: '='
  }
  templateUrl: "/assets/templates/components/customComponent/customComponent.html"
