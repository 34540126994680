@app.directive 'experimentVariation', (ExperimentService) ->
  restrict: 'A'
  controllerAs: 'experimentVariation'
  scope: {}
  transclude: true
  bindToController: {
    experimentName: '@'
    variationName: '@'
    variationCount: '@'
  }

  controller: ->
    # If no experimentName, show it; that makes it easy to use this
    # directive as a no-op in components that aren't being split
    @visible = (! @experimentName) || ExperimentService.isInVariation(@experimentName, @variationName, @variationCount)

    this

  template:
    """
      <div ng-if="experimentVariation.visible" style="display: inherit;">
        <div ng-transclude style="display: inherit;"></div>
      </div>
    """
