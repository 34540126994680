@app.directive 'brightcoveOverlay', ($sce, $timeout, $state, ModalService)->
  restrict: 'E'
  replace: false
  scope: {}
  controllerAs: 'brightcoveOverlay'
  bindToController:
    playerId: '@'


  controller: ($scope) ->
    $scope.currentUser = $state.currentUser
    this

  link: (scope) ->
    unless scope.currentUser
      overlayContent = '<div id="regForm" class="registration-form">
                                <p class="registration-form">
                                  <button id="loginBtn" class="registration-form button solid orange">Please login to continue</button>
                                </p>
                              </div>'
      overlayScriptElement = document.createElement("script")
      overlayScriptElement.src = "https://players.brightcove.net/videojs-overlay/1/videojs-overlay.min.js"
      document.body.appendChild(overlayScriptElement)
      overlayScriptElement.onload = ->
        showOverlay()

      showOverlay = ->
        videojs.getPlayer(scope.brightcoveOverlay.playerId).ready ->
          myPlayer = this
          playerOnReadyCallback(myPlayer)

      playerOnReadyCallback = (myPlayer) ->

        playVideo = ->
          # hide the overlay, reveal the controls, play
          myPlayer.addClass 'hide-overlay'
          myPlayer.removeClass 'hide-controls'
          myPlayer.play()

        onTimeupdateHandler = ->
          if myPlayer.currentTime() > 5
            myPlayer.pause()
            myPlayer.off 'timeupdate', onTimeupdateHandler
            myPlayer.addClass 'hide-controls'

        myPlayer.on('timeupdate', ->
          onTimeupdateHandler()
        )


        myPlayer.overlay({
          content: overlayContent
          overlays: [{
            align: 'top'
            start: 5
          }]
        })
        loginBtn = document.getElementById("loginBtn")

        loginBtn.addEventListener("click", ->
          ModalService.open('login', source: 'nav')
        )

  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedBrightcove/brightcoveOverlay/brightcoveOverlay.html"