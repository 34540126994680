@app.directive 'matrixItemCircle', (utilities) ->
  restrict: 'E'
  scope: {}
  controller: ->

    @content.description = utilities.customSanitize(@content.description)
    this
  controllerAs: 'matrixItemCircle'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemCircle/matrixItemCircle.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    track: '&'
  }
