@app.directive 'intlDropdownMobile', ($rootScope, $state, LocationService ) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'dropdown'
  controller: ($scope) ->

    @showDropdown = false

    @toggle = ->
      @showDropdown = !@showDropdown

    this

  templateUrl: "/assets/templates/directives/intlDropdownMobile/intlDropdownMobile.html"
