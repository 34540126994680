@app.controller 'JouleLandingResponsiveController', ($element, $rootScope, responsiveService, $window, mobileDetectionService) ->

  # Responsive Variables
  @isViewportMobile = responsiveService.isViewportMobile()
  @isMobileDevice = mobileDetectionService.isMobile
  @responsiveWidth = responsiveService
  angular.element($window).bind 'resize', =>
    @isViewportMobile = responsiveService.isViewportMobile()
    $rootScope.$safeApply()

  this
