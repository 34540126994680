@app.directive 'timeTempKey', (TimeTempService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'timeTempKey'
  controller: ->
    @axis = TimeTempService.axis
    @low = 1
    @recommended = 1.5
    @high = 3
    this

  bindToController: {

  }
  templateUrl: "/assets/templates/directives/timeTempGuide/timeTempKey.html"
