@app.directive 'csFetchActivityShortcode', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'csFetchActivityShortcode'
  bindToController:
    'slug': '@'
  controller: (ActivityService, $state, $element) ->
    $element.parent().addClass('has-recipe-card')
    @isLoaded = false
    activityRequest = ActivityService.get id: @slug, embedded: 'true'
    activityRequest.$promise
      .then (result) =>
        @result = result
        @altText = _.get(result, 'title', 'Recipe Image')
        @isLoaded = true
    this
  templateUrl: "/assets/templates/directives/shortcodeOutputs/csFetchActivityShortcode/csFetchActivityShortcode.html"
