# Used to show a banner at the top of the page, for example for marketing/sales

@app.directive 'flags', ($rootScope, $state, csConfig, $cookies, CookieService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'flag'
  controller: ($scope) ->
    @flags = $state.flagsData.flags

    messages = _.merge {}, @flags

    @showFlag = false
    @locationData = $state.locationData
    @internationalData = $state.internationalData
    @dismissed = $cookies.getObject 'cs_flag_dismissed'

    if @internationalData
      @country = _.find @internationalData.countries, (country) => country.code == @locationData.country

      if @country
        @message = messages[@country.code]
        if @message && @message.bannerText && !@dismissed
          @bannerText = @message.bannerText
          @bannerLink = @message.bannerLink
          
          @showFlag = true
          $rootScope.$emit 'flag.stateChange'

    @closeFlag = ->
      @showFlag = false

      dateNow = new Date()
      days = 60
      expires = new Date(dateNow.getTime() + days * 24 * 60 * 60 * 1000)

      CookieService.set('cs_flag_dismissed', true, expires)

      $rootScope.$emit 'flag.stateChange'

    this

  templateUrl: "/assets/templates/directives/flags/flags.html"
