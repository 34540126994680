@app.directive 'olapic', ($timeout) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'olapic'
  controller: ->

    this

  bindToController: {
    theme: '='
    content: '='
  }

  link: (scope, element, attrs) ->

    update = ->
      randomElementId = "olapic-#{Math.floor(Math.random()*100000)}"
      $(element).find('.olapic-content').attr('id', randomElementId)
      render = ->
        if window.olapic
          window.olapic.prepareWidget {
            'id': scope.olapic.content.instance
            'wrapper': randomElementId
          }, 'renderNow': true
        else
          # If the api isn't ready yet, try again a little later
          $timeout (-> render()), 500

      render()

    scope.$watch 'olapic.content.instance', update

  templateUrl: "/assets/templates/components/olapic/olapic.html"
