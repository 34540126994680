@app.directive 'filepickerPick', ->
  restrict: 'E',
  replace: true,
  require: '?ngModel',
  scope: {}
  link: (scope, element, attrs, ngModel) ->
    filepicker.setKey "ANAsscmHGSKqZCHObvuK6z"
    scope.ngModel = ngModel

  controllerAs: 'filepickerPick'
  controller: ($scope, $element, $http) ->

    # console.log 'mimetypes: ', @mimetypes
    @pick = =>

      pickOptions =
        mimetypes: @mimetypes
        services: ['COMPUTER']

      onPickSuccess = (blob) ->
        # Something changed in Filepicker's v2 api (filepicker.read returns jibberish)
        # Fix here is to read the data directly instead of relying on filepicker.read
        $http.get blob.url
          .then (response) ->
            $scope.ngModel.$setViewValue(response.data)

      onPickError = (error) ->
        console.log error

      filepicker.pick pickOptions, onPickSuccess, onPickError

    this

  bindToController: {
    mimetypes: '='
  }

  templateUrl: "/assets/templates/directives/filepickerPick/filepickerPick.html"
