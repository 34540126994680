@app.directive 'csNellBackdrop', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'csNellBackdrop'
  bindToController:
    'isActive': '='
  controller: ($scope) ->
    @handleClick = ->
      $scope.$emit 'hideNellPopup'
    this
  template: "<div ng-show='csNellBackdrop.isActive' class='nell-backdrop' ng-click='csNellBackdrop.handleClick()'></div>"
