@app.directive 'downloadButtonForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'downloadButtonForm'
  controller: ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/downloadButton/downloadButtonForm.html"
]
