@app.directive 'csIngredient', ($timeout, csQuantityService) ->
  restrict: 'E'
  scope: {}
  bindToController:
    ingredient: '='
    ingredients: '='
    unit: '='
    isScalingMode: '='
    containerData: '='
    trustUnsanitized: '='
  controllerAs: 'csIngredient'
  controller: ($scope) ->
    @isFocused = false
    @isInvalid = false # TODO: use this in an ng-class in the template to show when a quantity is invalid
    @isFirstIngredient = false

    getFocusTarget = (quantity, unit) ->
      if !quantity.hasMass then return 'massless'
      else
        # avoid focusing on a hidden input
        if unit == 'metric'
          if quantity.g > 0 then return 'g'
          else if quantity.kg > 0 then return 'kg'
        if unit == 'imperial'
          if quantity.oz > 0 then return 'oz'
          else if quantity.lb > 0 then return 'lb'

    $scope.$on 'focusOnCsIngredient', =>
      if @isFirstIngredient
        # figure out which input to focus on, and send out a broadcast for focus-on directive
        $timeout (=> $scope.$broadcast getFocusTarget(@localQuantity, @unit)), 100

    $scope.$watch (=> @ingredient), =>
      # first ingredient can recieve focus when scaling mode is activated
      @isFirstIngredient = @ingredients.indexOf(@ingredient) == 0

    $scope.$watch (=> @ingredient.quantity), =>
      # make a local copy of quantity that can be used as a model for inputs
      # this can get messed up and become invalid without getting committed to the
      # shared model
      @localQuantity = _.clone @ingredient.quantity
      @isInvalid = false
      # do any special processing for unique ingredient types below
      if @ingredient.quantity.unit == 'a/n'
        @isAsNeeded = true # asNeeded gets special formatting
      if @ingredient.quantity.unit == 'recipe' && @ingredient.quantity.massless > 1
        @ingredient.quantity.unit = 'recipes' # recipe gets pluralized

    @handleBlur = (unit) =>
      commitQuantity unit
      @isFocused = false

    @handleFocus = =>
      @isFocused = true

    @handleEnter = (unit) ->
      commitQuantity unit

    @handleChange = (unit) ->
      @isInvalid = !checkValidity unit

    getRescaleFactor = (unit) =>
      csQuantityService.getRescaleFactor @localQuantity, @ingredient.origQuantity, unit

    checkValidity = (unit) ->
      factor = getRescaleFactor unit
      _.isFinite(factor) and factor > 0 and factor < 1000

    commitQuantity = (unit) =>
      if checkValidity unit
        $scope.$emit 'rescaleIngredients', getRescaleFactor unit
      else
        @isInvalid = true

    this
  templateUrl: "/assets/templates/directives/ingredients/csIngredient/csIngredient.html"
