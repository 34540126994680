@app.directive 'heroSlider', (csFilepickerMethods, $window, AnalyticsService, $rootScope, $timeout, $interval) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'heroSlider'
  controller: ($element, $scope) ->

    if @content.slideInterval
      @sliderInterval = @content.slideInterval
    else
      @sliderInterval = '4000'

    @flickityOptions =
      wrapAround: true
      autoPlay: @sliderInterval
      pageDots: true

    @heightOptionClass = =>
      "slider-height-#{@content.slideHeight}"


    this

  link: (scope, element, attrs) ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/heroSlider/heroSlider.html"
