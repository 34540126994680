@app.directive 'slider', ($animate)->
  restrict: 'E'
  replace: true
  scope: {}
  controllerAs: 'slider'
  controller: ($scope, $element) ->

    @currentItemIndex = 0
    items = @content.items
    itemsLength = items.length
    lastItemIndex = itemsLength - 1

    @slides = []
    @slides = items

    getNextIndex = (index) ->
      if index == lastItemIndex
        0
      else
        index + 1

    getPrevIndex = (index) ->
      if index == 0
        lastItemIndex
      else
        index - 1

    @slideTo = (index) ->
      @currentItemIndex = index

    @next = =>
      @currentItemIndex = getNextIndex(@currentItemIndex)
      @slideTo(@currentItemIndex)

    @prev = ->
      @currentItemIndex = getPrevIndex(@currentItemIndex)
      @slideTo(@currentItemIndex)

    @isSlideActive = (index) =>
      if index == @currentItemIndex
        'active'

    @hasLine = (index) ->
      if index != 0
        'has-line'

    $scope.$watch 'slider.currentItemIndex', (currentItemIndex) =>
      @slides.map (slide, index) ->
        switch index
          when currentItemIndex
            slide.position = 0
          when getPrevIndex(currentItemIndex)
            slide.position = -100
          when getNextIndex(currentItemIndex)
            slide.position = 100
          else
            slide.position = null

    # $scope.$on 'videoEnded', =>
    #   console.log 'VIDEO ENDED FROM SLIDER'
    #   @videoPlaying = false

    @slideTo(0)
    this

  link: (scope, element, attrs) ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/slider/slider.html"

@app.directive 'slide', ($animate, mobileDetectionService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'slide'
  require: '^slider'
  controller: ($element) ->
    @isMobile = mobileDetectionService.isMobile
    @slideTo = null
    @videoPlaying = false
    this

  link: (scope, element, attrs, sliderController, $rootScope) ->

    getCss = (position, opacity) ->
      {
        'transform': "translateX(#{position}%)"
        '-webkit-transform': "translateX(#{position}%)"
        '-moz-transform': "translateX(#{position}%)"
        '-ms-transform': "translateX(#{position}%)"
        '-o-transform': "translateX(#{position}%)"
        'opacity': opacity
      }

    scope.$watch 'slide.position', (newPosition, oldPosition) ->
      # Stop videos if slider is slid
      scope.slide.videoPlaying = false
      sliderController.videoPlaying = false

      if newPosition == 0 && oldPosition == 0
        element.css getCss(0,1)

      if newPosition == 0 && oldPosition != 0
        $animate.animate element, getCss(oldPosition, 0), getCss(newPosition, 1)

      if newPosition != 0 && oldPosition == 0
        $animate.animate element, getCss(oldPosition, 1), getCss(newPosition, 0)

      if newPosition != 0 && oldPosition != 0
        $animate.animate element, getCss(oldPosition, 1), getCss(newPosition, 0), 'slide-no-transition'

    scope.slide.togglePlay = ->
      scope.slide.videoPlaying = ! scope.slide.videoPlaying
      sliderController.videoPlaying = scope.slide.videoPlaying

    scope.$on 'videoEnded', ->
      scope.slide.videoPlaying = ! scope.slide.videoPlaying
      sliderController.videoPlaying = scope.slide.videoPlaying
      $rootScope.$safeApply()

  bindToController: {
    content: '='
    position: '='
    active: '='
    sliderVideoPlaying: '='
  }

  templateUrl: "/assets/templates/components/slider/slide.html"
