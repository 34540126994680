@app.directive 'customButton', (AnalyticsService, PricingService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'customButton'
  controller: ->
    @track = =>
      AnalyticsService.track "#{@content.type} Button Clicked", {title: @content.title, buttonUrl: @content.url, filename: @content.filename, context: @content.context}

    this
  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/customButton.html"
