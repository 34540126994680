@app.controller 'CookieConsentController', ($cookies, CookieConsentService) ->

  @cookieText = ->
    CookieConsentService.cookieText

  @buttonText = ->
    CookieConsentService.buttonText

  # Manual Cookie concent is disabled. We are using Evidon to manage cookie concent.
  # This code will be removed after finalizing the feature with evidon.
  @isConsentNeeded = ->
    false
    #CookieConsentService.isConsentNeeded()

  @canDoAnalytics = ->
    CookieConsentService.canDoAnalytics()

  @setConsentAccept = ->
    CookieConsentService.setConsentAccept()
  

  this

