@app.controller 'JouleIntlController', ($state, $element, ModalService, $scope, csConfig, MetadataService, $sce, moneyFormatService, InternationalService, ProductMetadataService ) ->
  @theme = 'light'
  @productGroupData = $state.productGroupsData
  @joule = @productGroupData.product_groups.joule
  @currency = @productGroupData.currency
  @buttonDisabled = false
  @locationData = $state.locationData
  @internationalData = $state.internationalData

  @currentCountry = _.find @internationalData.countries, (country) =>
    country.code == @locationData.country

  # Create money format function using the Money Format Service
  @moneyFormat = (price,format) ->
    format = format || 'short'
    moneyFormatService.moneyFormat(price, @currency, format)

  # Check if Joule is available
  @jouleIsAvailable = ->
    @joule.products.length > 0

  @jouleIsAvailableDirect = ->
    @jouleIsAvailable() && !@jouleIsOnlyAvailableOnAmazon()

  @jouleIsOnlyAvailableOnAmazon = ->
    @jouleIsAvailable() && @joule.only_available_on_amazon == true

  # See what variants are available
  @availableVariants = (variants) ->
    _.filter(variants, (variant) -> variant.orderable)


  @setUpJouleAvailability = ->
    if @jouleIsAvailable()
      # Is there an available Joule variant in stock?
      @variantsInStock = @availableVariants(@joule.products)
      # If YES, Pre-select first variant in stock
      if @variantsInStock.length > 0
        $scope.variantSelected = @variantsInStock[0]
        @jouleIsInStock = true
      # If NO, default to first out of stock variant
      else
        $scope.variantSelected = @joule.products[0]
        @jouleIsInStock = false

      # Find selected variant ID and populate initial button URL
      @variantSelectedSku = $scope.variantSelected.sku
      @buttonUrl = "#{csConfig.spreeEndpoint}/cart/add?skus=#{@variantSelectedSku}"

  @setUpImages = =>
    if @jouleIsAvailable()
      for variant in @joule.products
        if variant.product_type == 'stainless'
          @jouleSSImage = variant.hero_image_url

        if variant.product_type == 'white'
          @jouleWhiteImage = variant.hero_image_url

    else
      @jouleSSImage = 'https://d3awvtnmmsvyot.cloudfront.net/api/file/Xf3ZwOHDR3G2mhtcvwHA'

  # Update Prices
  @updatePrices = =>
    @joulePrice = parseInt($scope.variantSelected.price)

    if $scope.variantSelected.compare_to_price
      @jouleSlashPrice = parseInt($scope.variantSelected.compare_to_price)
    else
      @jouleSlashPrice = parseInt($scope.variantSelected.price)

    # Total price is the selected Joule price plus discounted bundle prices
    @totalPrice = @joulePrice + _.reduce($scope.variantSelected.add_on_bundles, ((sum, bundle) ->
      if bundle.selected
        return sum + parseInt(bundle.bundled_price)
      else
        return sum
    ), 0)

    totalAddOnSlashPrice = _.reduce($scope.variantSelected.add_on_bundles, ((sum, bundle) ->
      if bundle.selected
        return sum + parseInt(bundle.price)
      else
        return sum
    ), 0)
    # If bundle add-ons are selected or Joule has a slash price, the total
    # slash price is the selected Joule slash price plus undiscounted bundle prices
    if totalAddOnSlashPrice > 0 || @joulePrice < @jouleSlashPrice
      @totalSlashPrice = @jouleSlashPrice + totalAddOnSlashPrice
    else
      @totalSlashPrice = undefined

    @totalPrice = @moneyFormat(@totalPrice, 'long')
    if @totalSlashPrice
      @totalSlashPrice = @moneyFormat(@totalSlashPrice)

  # Update button URL
  @updateButton = =>
    # Update selected variant variable
    @variantSelectedSku = $scope.variantSelected.sku
    @variantSelectedType = $scope.variantSelected.product_type
    @variantSelectedStatus = $scope.variantSelected.status

    if @jouleIsInStock
      # Setting button text based on status
      if @variantSelectedStatus == "pre_order"
        @buttonText = "Preorder Joule"
      else
        @buttonText = "Buy Joule"

      skus = _.reduce($scope.variantSelected.add_on_bundles, ((selectedSkus, bundle) ->
        if bundle.selected
          return selectedSkus.concat(bundle.skus)
        else
          return selectedSkus
      ), [@variantSelectedSku])
      @buttonUrl = "#{csConfig.spreeEndpoint}/cart/add?skus=#{skus.join(',')}"

      # The selected bundles, identified by their tags, should remain consistently
      # selected when the customer switches between different variants.
      selectedBundleTags = _.map(_.filter($scope.variantSelected.add_on_bundles, { selected: true }), 'tag')
      @availableVariants(@joule.products).forEach((variant) ->
        variant.add_on_bundles.forEach((bundle) ->
          bundle.selected = _.includes(selectedBundleTags, bundle.tag)
        )
      )

    else if !@jouleIsInStock
      @buttonText = "Out of Stock"
      @buttonUrl = undefined

  # Keep selection updated
  $scope.updateSelection = =>
    if @jouleIsAvailableDirect()
      @updateButton()
      @updatePrices()
    @updateExternalLinks()

  @checkStock = (productVariant) ->
    if productVariant.orderable == true
      return true
    else
      return false

  @forceInUS = ->
    InternationalService.setCountry('US')
      .then (response) ->
        window.location.reload()

  @openInternationalJoule = ->
    ModalService.open('internationalJoule', {type: 'normal', sku: @sku, user: @currentUser, checkoutUrl: @url, outOfStock: !@joule.orderable })

  @addToCartButtonClick = =>
    @buttonDisabled = true
    window.location.assign @buttonUrl

  @discountPercent = (price, discountPrice) ->
    Math.floor((1 - (discountPrice / price)) * 100)

  @updateExternalLinks = =>
    if @joule.external_link_map? && $scope.variantSelected?
      variant = @joule.external_link_map[$scope.variantSelected.sku]

      @jouleExternalLinkText = if variant? then variant.link_text else @joule.amazon_link_text
      @jouleExternalLinkUrl = if variant? then variant.link_url else @joule.amazon_link_url
    else
      @jouleExternalLinkText = @joule.amazon_link_text
      @jouleExternalLinkUrl = @joule.amazon_link_url
  
  # Initialization
  @setUpJouleAvailability()
  @setUpImages()
  $scope.updateSelection()


  # SEO Metadata
  canonical = '/joule'
  title = 'Joule Sous Vide by ChefSteps'
  description = 'Say goodbye to overcooking. Joule heats to the perfect temperature - no more, no less - which means that your proteins won’t overcook, ever. Joule works with iPhone or Android—connect with Bluetooth alone, or cook from anywhere with WiFi.'
  image = 'https://d3awvtnmmsvyot.cloudfront.net/api/file/eP71dY8XQhx1vuvVFNxt'
  MetadataService.set
    title: title
    description: description
    keywords: "joule, sous vide, chefsteps, circulator, precision cooker"
    canonical: canonical
    image: image
    sousVideTargeted: true
    og:
      url: canonical

  ProductMetadataService.setJsonLdForProduct(@joule.products)


  this
