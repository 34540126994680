@app.directive 'csStep', ->
  restrict: 'E'
  replace: true
  scope: {}
  bindToController:
    step: '='
    stepNumber: '='
    unit: '='
    createdByAdmin: '='
    rescaleFactor: '='
  controllerAs: 'csStep'
  controller: ($scope, $http) ->
    @isAsideExpanded = false
    @toggleAsideExpanded = => @isAsideExpanded = !@isAsideExpanded

    isImageOnly = => (!@step.directions && !@step.title && !@stepNumber && !!@step.image)

    hasImage = => (!!@step.image)

    isRecipeCardOnly = =>
      re = /\[fetch(\w+)\s+([^\]]*)\]/ #regex to find shortcodes beginning with [fetch
      @step.directions?.match(re)?[0] == @step.directions #are the directions made up just of one of these?

    @getClasses = ->
      'aside': @step.isAside
      'aside-expanded': @isAsideExpanded
      'no-print': @step.isAside
      'theme-gray': @step.isAside
      'theme-light': !@step.isAside
      'step-image-only': isImageOnly()
      'has-step-image': hasImage()
      'step-recipe-card-only': isRecipeCardOnly()

    this
  templateUrl: "/assets/templates/directives/steps/csStep/csStep.html"
