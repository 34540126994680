@app.directive 'csSteps', ->
  restrict: 'E'
  scope: {}
  bindToController:
    steps: '='
    unit: '='
    createdByAdmin: '='
    rescaleFactor: '='
  controllerAs: 'csSteps'
  controller: ($scope) ->
    $scope.$watch (=> @steps), =>
      @stepNumbers = _.map @steps, (step, index, steps) ->
        if step.hideNumber
          0
        else
          prevStepsWithNumbers = _.filter (steps.slice 0, index), (step) -> !step.hideNumber
          prevStepsWithNumbers.length + 1

    this
  templateUrl: "/assets/templates/directives/steps/csSteps/csSteps.html"
