@app.directive 'claimSubscriptionGift', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'claimSubscriptionGift'

  controller: (AnalyticsService, subscriptionService, ModalService, $state, $timeout) ->
    @currentContent = undefined
    @state = undefined

    @currentUser = $state.currentUser
    @unclaimedGifts = []
    @claimedGifts = []
    @loading = false
    @claimLoading = false

    @signIn = ->
      ModalService.open('login', source: 'claimSubscriptionGift')

  
    @getGifts = =>
      @loading = true
      subscriptionService.getGifts()
        .then (gifts) =>
          @loading = false
          @unclaimedGifts = gifts.unclaimed
          @claimedGifts = gifts.claimed
          @setState(if @unclaimedGifts.length >= 1 then 'claimGifts' else 'noGifts')
        .catch (error) =>
          AnalyticsService.track 'getGifts error', {error: error}
          @loading = false
          @setState('error')

    @claimSubscriptionGifts = =>
      @claimLoading = true
      subscriptionService.claimSubscriptionGifts(@unclaimedGifts)
        .then (result) =>
          subscriptionService.waitForClaimComplete(@unclaimedGifts)
        .then ->
          subscriptionService.syncSubscriptions()
        .then (result) =>
          @claimLoading = false
          @setState('completed')
        .catch (error) =>
          AnalyticsService.track 'claimSubscriptionGifts error', {error: error}
          @claimLoading = false
          @setState('error')

    @updatePaymentMethod = ->
      AnalyticsService.track 'updatePaymentMethod is called'
      subscriptionService.updatePaymentMethod()

    # state is 'notSignedIn' | 'noGifts' | 'claimGifts' | 'completed' | 'error'
    # These are the settings that match in the claimSubscriptionGiftForm.haml
    @setState = (state) =>
      AnalyticsService.track 'claimSubscriptionGift state transition', {previousState: @state, nextState: state}
      @state = state
      @contentCopy = Object.assign({}, @content[state])
      if @contentCopy.description?
        @contentCopy.description = @applySubstituions(@contentCopy.description)
      @currentContent = @contentCopy
      
    @substitutions = [
      {
        token: "|email|"
        replacement: -> if $state.currentUser?.email? then $state.currentUser.email else "email"
      }
    ]

    # Applies substitutions based on current state and returns a new string
    @applySubstituions = (input) =>
      result = input.slice(0)
      @substitutions.forEach (sub) ->
        result = result.replace(sub.token, sub.replacement())
      
      return result

    @init = =>
      if $state.currentUser?
        @getGifts()
      else
        @setState('notSignedIn')

    @init()
    this

  bindToController: {
    theme: '='
    content: '='
  }

  templateUrl: "/assets/templates/components/claimSubscriptionGift/claimSubscriptionGift.html"
