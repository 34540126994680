@app.directive 'expandable', ($window, $rootScope, $timeout)->
  restrict: 'A'
  # scope: {}
  controllerAs: 'expandable'
  transclude: true
  bindToController: {
  }
  templateUrl: "/assets/templates/directives/expandable/expandable.html"

  controller: ->
    @expanded = false
    this

