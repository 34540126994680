# Modal that accepts a content object
# For example:
# data =
#   title: 'Hello'
#   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque laoreet accumsan quam eu convallis. In maximus tellus sed arcu imperdiet, et feugiat ex fermentum. Nam id nulla pharetra, convallis diam eget, finibus libero. Integer eleifend fringilla magna. Donec dui turpis, consectetur vitae erat at, iaculis vehicula tellus. Proin quis tellus et orci consequat facilisis. Phasellus semper ex in rutrum pharetra. Pellentesque vehicula augue odio, eu bibendum eros feugiat at. Proin in nisi consectetur, interdum felis pellentesque, gravida leo. Sed convallis commodo dolor, vitae tincidunt enim tincidunt quis. Curabitur nec sapien hendrerit, ultrices diam at, viverra mauris. Nunc id vestibulum tortor. Nullam lacus nibh, accumsan eget cursus sed, pharetra a elit. Quisque leo eros, sodales eget magna eget, fermentum congue ipsum.'
#   image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/MOvuHAWBQ26nKqKEQ7re'
# ModalService.open('content', {content: data})

@app.directive 'modalContent', (ModalService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @content = ModalService.modal.params.content
    @close = ->
      ModalService.reject()
    this

  controllerAs: 'modalContent'
  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/modals/modalContent/modalContent.html"
