@app.directive 'textBlock', ($filter, $compile, $sanitize)->
  restrict: 'E'
  scope: {}
  controllerAs: 'textBlock'
  controller: ->

    @isExpanded = !@content.isExpandable
    @description = @content.description || ''
    conf = {
      "<b>": "<span class='strong'>",
      "<i>": "<span class='italic'>",
      "</b>": "</span>",
      "</i>": "</span>",
      '<h1 class="': '<div class="text-block-content"><span class="h1-text-block ',
      '<h2 class="': '<div class="text-block-content"><span class="h2-text-block ',
      '<h3 class="': '<div class="text-block-content"><span class="h3-text-block ',
      '<h4 class="': '<div class="text-block-content"><span class="h4-text-block ',
      '<h5 class="': '<div class="text-block-content"><span class="h5-text-block ',
      '<h6 class="': '<div class="text-block-content"><span class="h6-text-block ',
      "<h1": "<div class='text-block-content'><span class='h1-text-block'",
      "<h2": "<div class='text-block-content'><span class='h2-text-block'",
      "<h3": "<div class='text-block-content'><span class='h3-text-block'",
      "<h4": "<div class='text-block-content'><span class='h4-text-block'",
      "<h5": "<div class='text-block-content'><span class='h5-text-block'",
      "<h6": "<div class='text-block-content'><span class='h6-text-block'",
      "</h1>": "</span></div>",
      "</h2>": "</span></div>",
      "</h3>": "</span></div>",
      "</h4>": "</span></div>",
      "</h5>": "</span></div>",
      "</h6>": "</span></div>",
      '<p class="': '<div class="paragraph-content ',
      "</p>": "</div>"
    }

    _.each(conf, (value, key, _obj) =>  @description = @description.replace(new RegExp(key, 'g'), value) )

    @toggleExpand = =>
      @isExpanded = !@isExpanded

    this

  bindToController: {
    theme: '='
    content: '='
  }
  link: (scope, element, attrs) ->

  templateUrl: "/assets/templates/components/textBlock/textBlock.html"
