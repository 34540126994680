@app.controller 'ComponentEditController', ($scope, ComponentService, $stateParams) ->

  ComponentService.show {id: $stateParams.id}, ((data) =>
    @type = data.componentType
    @name = data.name
    @meta = data.meta
    _.defaults  @meta,
      experiment:
        variationCount: 2
    @parentType = data.componentParentType
    @parentId = data.componentParentId
    @position = data.position
    @applyToCountries = data.applyToCountries
    @countries = data.countries
    if data.scheduleStartDate
      @isSchedule = true
      @scheduleStartDate = data.scheduleStartDate
      @minStartDate = if new Date(data.scheduleStartDate) < new Date() then data.scheduleStartDate else new Date()
    else
      @isSchedule = false
      @scheduleStartDate = new Date()
      @minStartDate = new Date()
    @scheduleStartDate = moment(@scheduleStartDate).startOf('day').toDate()
    @minStartDate = moment(@minStartDate).startOf('day').toDate()
    @scheduleEndDate = if data.scheduleEndDate then moment(data.scheduleEndDate).toDate() else ''
    @minEndDate = @minStartDate
  ), ((error) =>
    @showNewButton = true
  )

  this
