@app.directive 'bannerText', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerText'
  controller: ->


    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/banner/bannerText/bannerText.html"
