# Handles rescaling quantities
@app.service 'csQuantityService', ->


  isMetricUnit = _.partial _.includes, ['g', 'kg']
  isImperialUnit = _.partial _.includes, ['oz', 'lb']

  roundSensible = (val) ->
    # Round to a sensible number of digits after the decimal
    decDigits = 2
    decDigits = 1 if val > 1
    decDigits = 0 if val > 50
    mult = Math.pow(10, decDigits)
    val = Math.round(val * mult) / mult
    val

  imperialToOz = (obj) ->
    oz = 0
    if obj.oz then oz += obj.oz
    if obj.lb then oz += obj.lb * 16
    oz

  metricToG = (obj) ->
    g = 0
    if obj.g then g += obj.g
    if obj.kg then g += obj.kg * 1000
    g

  resolveImperial = (obj) ->
    totalOz = imperialToOz(obj)
    lb = Math.floor (totalOz / 16)
    oz = totalOz % 16
    lb: lb, oz: oz

  resolveMetric = (obj) ->
    totalG = metricToG(obj)
    if totalG >= 1000
      kg: (totalG/1000), g: 0
    else
      kg: 0, g: totalG

  metricToImperial = (obj) ->
    totalOz = metricToG(obj) * 0.03527
    resolveImperial oz: totalOz

  imperialToMetric = (obj) ->
    totalG = imperialToOz(obj) * 28.35
    resolveMetric totalG

  @toScalableQuantity = (val, unit) ->
    quantity = {}
    if isMetricUnit unit
      metricQuantity = {}
      metricQuantity[unit] = val
      metricQuantity = resolveMetric metricQuantity
      imperialQuantity = metricToImperial metricQuantity
      quantity.g = roundSensible metricQuantity.g
      quantity.kg = roundSensible metricQuantity.kg
      quantity.oz = roundSensible imperialQuantity.oz
      quantity.lb = roundSensible imperialQuantity.lb
      quantity.hasMass = true
    else
      quantity.hasMass = false
      quantity.massless = val
      quantity.unit = unit
    quantity

  @scaleQuantity = (quantity, factor) ->
    scaledQuantity = hasMass: quantity.hasMass
    if !quantity.hasMass
      scaledQuantity.massless = roundSensible (quantity.massless * factor)
      scaledQuantity.unit = quantity.unit
    else
      # scale and resolve
      scaledMetricQuantity = resolveMetric
        g: (quantity.g * factor)
        kg: (quantity.kg * factor)
      scaledImperialQuantity = resolveImperial
        oz: (quantity.oz * factor)
        lb: (quantity.lb * factor)
      # then round
      scaledQuantity.g = roundSensible scaledMetricQuantity.g
      scaledQuantity.kg = roundSensible scaledMetricQuantity.kg
      scaledQuantity.oz = roundSensible scaledImperialQuantity.oz
      scaledQuantity.lb = roundSensible scaledImperialQuantity.lb
    scaledQuantity

  @getRescaleFactor = (newQuantity, oldQuantity, unit) ->
    if isMetricUnit unit
      factor = (metricToG newQuantity) / (metricToG oldQuantity)
    else if isImperialUnit unit
      factor = (imperialToOz newQuantity) / (imperialToOz oldQuantity)
    else if unit == 'massless'
      factor = newQuantity.massless / oldQuantity.massless
    factor

  this
