@app.directive 'facebookAuthButton', (ModalService, AnalyticsService, $state, $location, authenticationService, $http, $rootScope, csAdwords, csFacebookConversion, formHelper, localStorageService, csConfig, ssoService, $q, $timeout)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->

    # The following is taken originally from playground
    # The intention is for this to move to cs-shared
    @facebook = =>

      @showSpinner = true


      loginSuccess = (response) =>
        console.log('loginSuccess response: ', response)
        @showSpinner = false
        if @sso
          @state = $location.search().state
          ssoService.signAndRedirect(@state)
        else
          if response.newUser
            # This is questionable - if they are logging in via facebook instead
            # of signing up via facebook, should we really be tracking a conversion?
            # I guess the issue is that, as of now we don't really know whether
            # they were already in the DB - we could pass that back from the server.
            csAdwords.track(998032928,'77TfCIjjrAgQoIzz2wM')
            csFacebookConversion.track(6014798030226,0.00)

            # Mixpanel
            properties = {source : 'modal', method: 'facebook'}
            AnalyticsService.reidentify()
            AnalyticsService.track 'Signed Up JS', properties

            # Google Analytics
            AnalyticsService.track 'Complete', { category: 'Sign Up' }
          if @ftue && response.newUser
            ModalService.resolveSuccess('modalConsent')
          else
            ModalService.resolveSuccess()

      loginError = (error) =>
        @showSpinner = false
        if error.message == 'User cancelled login or did not fully authorize.'
          @errorMessage = "Please try again. Click 'okay' to allow ChefSteps to sign in with Facebook"
        else if error.status == 400
          # Email Missing from FB Account
          pathname = window.location.pathname
          if ((/^\/sso/).test(pathname))
            $location.search(_.extend $location.search(), {csOnly: true})
            $location.replace()
            window.location = $location.absUrl()
          else
            ModalService.reject()
            $timeout ->
              ModalService.open('login', {csOnly: true, authMessage: 'Sorry, we were not able to login with Facebook because there is no email address associated with that account.  Please create a new ChefSteps account or sign in with an existing one.'})

        # TODO Handle situation where a ChefSteps account tries to sign in with Facebook
        # if error.status == 401
        #   ModalService.open('requirePassword', {email: error.user.email})

      authenticationService.loginWithFacebook(window.fbResponse)
        .then loginSuccess, loginError

    this

  controllerAs: 'facebookAuthButton'
  bindToController: {
    sso: '='
    ftue: '='
  }
  templateUrl: "/assets/templates/directives/ssoForm/facebookAuthButton/facebookAuthButton.html"
