@app.controller 'JouleLandingSVController', ($element, $rootScope, responsiveService, $window, mobileDetectionService ) ->
  @content =
    title: "What Is Sous Vide?"
    description: "It sounds like some fancy-pants French thing, but sous vide (say “sue veed”) is a pretty simple technique. Food cooks in precisely controlled, low-temp water to the exact doneness you like. Because the food can never get hotter than the water, under- or overcooking is almost impossible. Voilà, perfection!"
    image: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/bHkbSdSO3UcbcV1CKgvN'
    youtubeId: 'QBjjIWMYH8s'
  @theme = 'light'

  @getAspect = ->
    return 'portrait' if ($element.children()[0].clientHeight / 9) > ($element.children()[0].clientWidth / 16)
    return 'landscape'

  @isVideoVisible = false

  @showVideo = =>
    @isVideoVisible = true
    $rootScope.$broadcast 'nav.hideNav', @isVideoVisible

  @hideVideo = =>
    @isVideoVisible = false
    $rootScope.$broadcast 'nav.hideNav', @isVideoVisible

  @handleEmbedVideoClicked = => @hideVideo()


  # Responsive Variables
  @isViewportMobile = responsiveService.isViewportMobile()
  @isMobileDevice = mobileDetectionService.isMobile
  @responsiveWidth = responsiveService
  angular.element($window).bind 'resize', =>
    @isViewportMobile = responsiveService.isViewportMobile()
    $rootScope.$safeApply()

  this
