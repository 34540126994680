@app.directive 'feedForm', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'matrix'
  controller: ($scope) ->
    @feedTypeOptions = ['activity', 'search']
    @itemTypeOptions = ['Media', 'List', 'Circle', 'Card']

    @applyClass = =>
      matrixClass = []
      matrixClass.push "matrix-#{@columns}-col"

      matrixClass.push "matrix-#{@itemType.toLowerCase()}"
      matrixClass.join ' '

    this

  bindToController: {
    items: '='
    rows: '='
    columns: '='
    itemType: '='
    theme: '='
    feedType: '='
    searchQuery: '='
    descriptionCharLimit: '='
  }

  templateUrl: "/assets/templates/components/feed/feedForm.html"
