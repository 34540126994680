@app.directive 'csBackgroundVideo', ($window, mobileDetectionService, $rootScope) ->
  restrict: 'E'
  scope: {}
  controller: ->
    # For background video, prefer Youtube
    # It seems to perform better especially on slow connections
    @isMobile = mobileDetectionService.detect()
    @showNative = true if @videoSources and !@isMobile
    @showYoutube = true if @youtubeId and !@videoSources and !@isMobile
    @showVimeo = true if @vimeoId and !@videoSources and !@youtubeId and !@isMobile
    @showBrightcove = true if @brightcoveVideoId and !@videoSources and !@isMobile
    @loop = @loop || 0
    this
  controllerAs: 'csBackgroundVideo'
  bindToController: {
    youtubeId: '@'
    vimeoId: '@'
    imageUrl: '@'
    tabIndex: '@'
    brightcoveVideoId: '@'
    brightcovePlayerId: '@'
    stepOrder: '@'
    videoSources: '='
    playVideo: '='
    autoplay: '='
    loop: '='
    mute: '='
  }
  link: (scope, element, attrs) ->

    calculateDimensions = ->
      width = element[0].clientWidth
      height = element[0].clientHeight
      return if width == 0 || height == 0
      heroAspectRatio = width/height
      videoAspectRatio = 16/9
      if heroAspectRatio >= videoAspectRatio
        # Hero is more landscape
        videoWidth = width
        videoHeight = width/videoAspectRatio
        translate = (videoHeight - height)/2
        scope.csBackgroundVideo.styles =
          width: "#{videoWidth}px"
          height: "#{videoHeight}px"
          transform: "translateY(-#{translate}px)"
      else
        # Hero is more portrait
        videoWidth = height*videoAspectRatio
        videoHeight = height
        translate = (videoWidth - width)/2
        scope.csBackgroundVideo.styles =
          width: "#{videoWidth}px"
          height: "#{videoHeight}px"
          transform: "translateX(-#{translate}px)"

    calculateDimensions()

    angular.element($window).on 'resize', ->
      calculateDimensions()
      $rootScope.$safeApply()

    scope.$on 'videoReady', ->
      if scope.csBackgroundVideo.autoplay == 1
        scope.csBackgroundVideo.imageClass = 'cs-background-video-image-hidden'
        scope.csBackgroundVideo.playVideo = true
        scope.csBackgroundVideo.videoPlayingClass = 'cs-background-video-playing'
        $rootScope.$safeApply()

      scope.$watch 'csBackgroundVideo.playVideo', (playVideo) ->
        if playVideo == true
          scope.csBackgroundVideo.videoPlayingClass = 'cs-background-video-playing'
          # scope.$broadcast 'playVideoClicked'
        else
          scope.csBackgroundVideo.videoPlayingClass = 'cs-background-video-paused'
          # scope.$broadcast 'playVideoClicked'

  templateUrl: "/assets/templates/directives/csBackgroundVideo/csBackgroundVideo.html"
