@app.directive 'csEmbedNative', ($sce)->
  restrict: 'E'
  replace: false
  scope: {}
  bindToController:
    videoSources: '='
    adjustHeight: '@'
    loop: '='
    autoplay: '@'
    controls: '@'
    mute: '@'
    playVideo: '='
  controllerAs: 'csEmbedNative'
  controller: ($scope) ->
    @trustedVideoUrl = (source) ->
      $sce.trustAsResourceUrl(source)
    this

  link: (scope, element, attrs) ->

    videoElement = element.find('video')
    videoElement.bind 'loadeddata', ->
      scope.$emit 'videoReady'

    videoElement.bind 'ended', ->
      scope.$emit 'videoEnded'

    scope.$watch 'csEmbedNative.playVideo', (newValue) ->
      if newValue
        videoElement[0]?.play?()
      else
        videoElement[0]?.pause?()

    scope.$watch 'csEmbedNative.loop', (loopVideo) ->
      videoElement[0]?.loop = loopVideo


  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedNative/csEmbedNative.html"
