@app.directive 'waiting', (ModalService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->

    @close = ->
      ModalService.resolve()

    this

  controllerAs: 'waiting'
  templateUrl: "/assets/templates/modals/waiting/waiting.html"
