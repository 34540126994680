@app.directive 'olapicForm', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'olapic'
  controller: ($scope) ->
    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/olapic/olapicForm.html"
