@app.directive 'dividerForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'divider'
  controller: ->
    @heightTypeOptions = ['standard', 'short']

    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/divider/dividerForm.html"
]
