@app.service 'CommentService', ($http, $state, csConfig, $q) ->

  # Comment Input Toolbar
  @toolbar = [
    ['bold','italics']
    ['csInsertImage']
  ]

  handleError = (error) ->
    console.log 'error: ', error
    Rollbar.error("CommentService: #{error.data}")

  @like = (commentId) ->
    deferred = $q.defer()
    if $state.currentUser
      userData =
        id: $state.currentUser.id.toString()
        name: $state.currentUser.name
      bloomAuth = encodeURIComponent($state.currentUser.encrypted_bloom_info)
      $http.post("#{csConfig.bloomApiEndpoint}/comments/#{commentId}/like?apiKey=xchefsteps&auth=#{bloomAuth}&sso=true").then (response) ->
        deferred.resolve(userData)
    else
      deferred.reject()

    deferred.promise

  @unlike = (commentId) ->
    deferred = $q.defer()
    if $state.currentUser
      userData =
        id: $state.currentUser.id.toString()
        name: $state.currentUser.name
      bloomAuth = encodeURIComponent($state.currentUser.encrypted_bloom_info)
      $http.delete("#{csConfig.bloomApiEndpoint}/comments/#{commentId}/like?apiKey=xchefsteps&auth=#{bloomAuth}&sso=true").then (response) ->
        deferred.resolve(userData)
    else
      deferred.reject()

    deferred.promise

  @liked = (commentLikes) ->
    if $state.currentUser
      _.findWhere commentLikes, {id: $state.currentUser?.id.toString()}
    else
      false

  @post = (commentData) ->
    deferred = $q.defer()
    if $state.currentUser
      bloomAuth = encodeURIComponent($state.currentUser.encrypted_bloom_info)
      $http.post("#{csConfig.bloomApiEndpoint}/comments?apiKey=xchefsteps&auth=#{bloomAuth}&sso=true", {comment: commentData}).then ((response) ->
        deferred.resolve(response)
      ), (error) ->
        handleError(error)
        deferred.reject()
    else
      deferred.reject()

    deferred.promise

  @put = (commentId, content) ->
    deferred = $q.defer()
    if $state.currentUser
      bloomAuth = encodeURIComponent($state.currentUser.encrypted_bloom_info)
      $http.put("#{csConfig.bloomApiEndpoint}/comments/#{commentId}?apiKey=xchefsteps&auth=#{bloomAuth}&sso=true", {content: content}).then (response) ->
        deferred.resolve(response)
    else
      deferred.reject()

    deferred.promise

  @delete = (commentId) ->
    deferred = $q.defer()
    if $state.currentUser
      bloomAuth = encodeURIComponent($state.currentUser.encrypted_bloom_info)
      $http.delete("#{csConfig.bloomApiEndpoint}/comments/#{commentId}?apiKey=xchefsteps&auth=#{bloomAuth}&sso=true").then (response) ->
        deferred.resolve(response)
    else
      deferred.reject()

    deferred.promise

  @canEdit = (commentData) ->
    if $state.currentUser
      isAuthor = commentData.author.id == $state.currentUser?.id.toString()
      isAdmin = $state.currentUser.admin
      isAuthor || isAdmin
    else
      false

  this
