@app.directive 'csEmbedVideo', (csConfig) ->
  restrict: 'E'
  replace: false
  scope: {}
  bindToController:
    youtubeId: '@'
    vimeoId: '@'
    brightcovePlayerId: '@'
    brightcoveVideoId: '@'
    showOverlay: '@'
    stepOrder: '@'
    tabIndex: '@'
    videoSources: '='
    autoplay: '='
  controllerAs: 'csEmbedVideo'
  controller: ($scope) ->
    @autoplay = @autoplay || 0
    @getType = =>
      if @videoSources
        'native'
      else if @brightcoveVideoId
        'brightcove'
      else if @vimeoId && csConfig.enableVimeoPlayer
        'vimeo'
      else if @youtubeId
        'youtube'
    this

  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedVideo.html"
