@app.directive 'csFetchPdfShortcode', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'csFetchPdfShortcode'
  bindToController:
    'slug': '@'
  controller: ($http) ->
    @isLoaded = false
    $http.get("/api/v0/embed_pdfs/#{@slug}")
      .then (result) =>
        @result = result['data']
        @isLoaded = true
    this
  templateUrl: "/assets/templates/directives/shortcodeOutputs/csFetchPdfShortcode/csFetchPdfShortcode.html"
