@app.service 'PricingService', ($q, $http, utilities, LocationService, csConfig) ->

  # Make available as constants to avoid repetion in the app
  @PREMIUM_SKU = "cs10002"
  @JOULE_SKU = "cs10001"

  @errorRow =
    sku: "ERROR"
    title: "ERROR"
    price: 999999
    msrp: 99999

  @priceTable = null

  @askInternationalOverride = (sku) ->
    (sku == @JOULE_SKU) && (! LocationService.isInUS())

  @loadData = =>
    deferred = $q.defer()
    if @priceTable
      deferred.resolve()
    else
      $http.get("#{csConfig.chefstepsEndpoint}/api/v0/products.json").then(
        (response) =>
          @priceTable = response.data
          deferred.resolve(@priceTable)
        ,(error) ->
          deferred.reject(error)
      )
    deferred.promise

  @getPrice = (sku, {userIsPremium, usedCirculatorDiscount}) =>
    row = @priceTable.products[sku]
    if ! row?
      row = @errorRow
      Rollbar.error("PricingService: unexpected sku #{sku}")

    result = _.pick(row, 'sku', 'title', 'price', 'msrp', 'shippable')

    if (userIsPremium && !usedCirculatorDiscount) && row.premiumPrice?
      result.price = row.premiumPrice

    result

  this
