@app.directive 'csEmbedVimeo', ($timeout) ->
  restrict: 'E'
  scope: {}
  bindToController:
    vimeoId: '@'
    autoplay: '@'
    loop: '@'
    playVideo: '='
  controllerAs: 'csEmbedVimeo'
  link: (scope, element) ->
    createPlayer = =>
      if $f?
        @playerId = "VIMEO" + Date.now()
        iframe = $(element).find('.video-iframe iframe')[0]
        $(iframe).attr('id', @playerId)
        @player = $f(iframe)
      else
        # handle case when froogaloop API is not loaded yet
        $timeout createPlayer, 500
    createPlayer()
  controller: ($scope) ->
    @id = @vimeoId
    @loop = @loop || 0
    handlePlayVideoClicked = (e, isVideoPlaying) ->
      @player.api(if isVideoPlaying then 'pause' else 'play')

    $scope.$on('playVideoClicked', handlePlayVideoClicked)

    this
  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedVimeo/csEmbedVimeo.html"
