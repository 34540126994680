@app.directive 'standardButtonForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'standardButtonForm'
  controller: ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/standardButton/standardButtonForm.html"
]
