@app.service 'TimeTempService', ->
  @basicItems = [
    {
      ingredientType: 'Beef'
      title: 'Steak Rare'
      temp:
        c: 54
        f: 129
      time:
        low: .75
        high: 2
        recommended: 1
    }
    {
      ingredientType: 'Beef'
      title: 'Roast Medium Rare'
      temp:
        c: 60
        f: 140
      time:
        low: 5
        high: 14
        recommended: 6
    }
    {
      ingredientType: 'Pork'
      title: 'Chop Medium Rare'
      temp:
        c: 62
        f: 144
      time:
        low: 1
        high: 3
        recommended: 1
    }
    {
      ingredientType: 'Chicken'
      title: 'Light Meat Juicy and Tender'
      temp:
        c: 65
        f: 149
      time:
        low: 0.75
        high: 3
        recommended: 1
    }
    {
      ingredientType: 'Chicken'
      title: 'Dark Meat Juicy and Tender'
      temp:
        c: 75
        f: 167
      time:
        low: 0.5
        high: 5
        recommended: .75
    }
    {
      ingredientType: 'Fish'
      title: 'Tender and Flaky'
      temp:
        c: 50
        f: 122
      time:
        low: 0.25
        high: 70/60
        recommended: 4/6
    }
    {
      ingredientType: 'Egg'
      title: 'Poached'
      temp:
        c: 64
        f: 147
      time:
        low: 4/6
        high: 1.5
        recommended: 1
    }
    {
      ingredientType: 'Green Vegetables'
      title: 'Tender'
      temp:
        c: 82
        f: 180
      time:
        low: 1/6
        high: 1/3
        recommended: 1/6
    }
    {
      ingredientType: 'Potato Whole'
      title: 'Tender'
      temp:
        c: 85
        f: 185
      time:
        low: 1
        high: 3
        recommended: 1.5
    }
  ]

  @beefSteakItems = [
    {
      title: 'Rare'
      temp:
        c: 54
        f: 129
      time:
        low: .74
        high: 2
        recommended: 1
    }
    {
      title: 'Medium'
      temp:
        c: 58
        f: 136
      time:
        low: .74
        high: 2
        recommended: 1
    }
    {
      title: 'Well Done'
      temp:
        c: 68
        f: 154
      time:
        low: .74
        high: 2
        recommended: 1
    }
  ]

  @beefRoastItems = [
    {
      title: 'Rare'
      temp:
        c: 56
        f: 133
      time:
        low: 5
        high: 16
        recommended: 7
    }
    {
      title: 'Medium Rare'
      temp:
        c: 60
        f: 140
      time:
        low: 5
        high: 14
        recommended: 6
    }
    {
      title: 'Well Done'
      temp:
        c: 70
        f: 158
      time:
        low: 5
        high: 11
        recommended: 5
    }
  ]

  @beefToughItems = [
    {
      title: 'Rare'
      temp:
        c: 55
        f: 131
      time:
        low: 24
        high: 48
        recommended:24
    }
    {
      title: 'Medium Rare'
      temp:
        c: 65
        f: 149
      time:
        low: 16
        high: 24
        recommended: 24
    }
    {
      title: 'Well Done'
      temp:
        c: 85
        f: 185
      time:
        low: 7
        high: 16
        recommended: 8
    }
  ]

  @porkChopItems = [
    {
      title: 'Rare'
      temp:
        c: 58
        f: 136
      time:
        low: 1
        high: 3
        recommended: 1
    }
    {
      title: 'Medium Rare'
      temp:
        c: 62
        f: 144
      time:
        low: 1
        high: 3
        recommended: 1
    }
    {
      title: 'Well Done'
      temp:
        c: 70
        f: 158
      time:
        low: 1
        high: 3
        recommended: 1
    }
  ]

  @porkRoastItems = [
    {
      title: 'Rare'
      temp:
        c: 58
        f: 136
      time:
        low: 2.5
        high: 5.5
        recommended: 3
    }
    {
      title: 'Medium Rare'
      temp:
        c: 62
        f: 144
      time:
        low: 2.5
        high: 4
        recommended: 3
    }
    {
      title: 'Well Done'
      temp:
        c: 70
        f: 158
      time:
        low: 2.5
        high: 3.5
        recommended: 3
    }
  ]

  @porkToughItems = [
    {
      title: 'Rare'
      temp:
        c: 60
        f: 140
      time:
        low: 8
        high: 24
        recommended: 8
    }
    {
      title: 'Medium Rare'
      temp:
        c: 68
        f: 154
      time:
        low: 7
        high: 24
        recommended: 8
    }
    {
      title: 'Well Done'
      temp:
        c: 85
        f: 185
      time:
        low: 6
        high: 16
        recommended: 8
    }
  ]

  @chickenLightItems = [
    {
      title: 'Super-Supple'
      temp:
        c: 60
        f: 140
      time:
        low: .9
        high: 3
        recommended: 1
    }
    {
      title: 'Tender and Juicy'
      temp:
        c: 65
        f: 149
      time:
        low: .9
        high: 3
        recommended: 1
    }
    {
      title: 'Well Done'
      temp:
        c: 75
        f: 167
      time:
        low: .9
        high: 3
        recommended: 1
    }
  ]

  @chickenDarkItems = [
    {
      title: 'Tender'
      temp:
        c: 65
        f: 149
      time:
        low: .9
        high: 5
        recommended: .75
    }
    {
      title: 'Falling Off The Bone'
      temp:
        c: 75
        f: 167
      time:
        low: .9
        high: 5
        recommended: .75
    }
  ]

  @fishItems = [
    {
      title: 'Tender'
      temp:
        c: 40
        f: 104
      time:
        low: .4
        high: 70/60
        recommended: 2/3
    }
    {
      title: 'Tender and Flaky'
      temp:
        c: 50
        f: 122
      time:
        low: .4
        high: 70/60
        recommended: 2/3
    }
    {
      title: 'Well Done'
      temp:
        c: 55
        f: 131
      time:
        low: .4
        high: 70/60
        recommended: 2/3
    }
  ]

  @vegetableItems = [
    {
      title: 'Green Vegetables'
      temp:
        c: 82
        f: 180
      time:
        low: 1/6
        high: 1/3
        recommended: 1/6
    }
    {
      title: 'Winter Squash'
      temp:
        c: 85
        f: 185
      time:
        low: 1
        high: 3
        recommended: 1
    }
    {
      title: 'Potatoes & Root Vegetables'
      temp:
        c: 85
        f: 185
      time:
        low: 1
        high: 3
        recommended: 2
    }
  ]

  @fruitItems = [
    {
      title: 'Warm & Ripe'
      temp:
        c: 68
        f: 154
      time:
        low: 1
        high: 2.5
        recommended: 1.75
    }
    {
      title: 'Cooked to Soft (for purees, etc.)'
      temp:
        c: 85
        f: 185
      time:
        low: 1/3
        high: 1.5
        recommended: .5
    }
  ]


  @axis = [1/60, 0.5, 1, 2, 3, 6, 12, 24, 48]

  breakpoint1 = 1/60 # 1 Minute
  breakpoint2 = 4 # 4 hours
  breakpoint3 = 12
  breakpoint4 = 24
  breakpoint5 = 50

  @calcPosition = (time) ->

    if 0 <= time < breakpoint1
      position = 0.3*time/breakpoint1
    else if breakpoint1 <= time < breakpoint2
      position = 0.4*time/breakpoint2 + 0.3
    else if breakpoint2 <= time < breakpoint3
      position = 0.1*time/breakpoint3 + 0.7
    else if breakpoint3 <= time < breakpoint4
      position = 0.1*time/breakpoint4 + 0.8
    else if breakpoint4 <= time < breakpoint5
      position = 0.1*time/breakpoint5 + 0.9
    position*100

  this
