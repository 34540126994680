@app.directive 'customButtonForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'customButton'
  controller: ->
    @themeOptions = ['outline light', 'outline orange', 'outline red', 'outline dark', 'solid light', 'solid orange', 'solid red', 'solid dark', 'button-underline']
    @typeOptions = ['standard', 'download', 'signin', 'signup', 'shopify', 'spree', 'subscription', 'multipleSubscription', 'premium coupon', 'trial coupon', 'trial or extend coupon']

    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/customButton/customButtonForm.html"
]
