@app.directive 'customComponentForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'customComponentForm'
  controller: ->

    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/customComponent/customComponentForm.html"
]
