csElements = [
  'cs_h1'
  'cs_h2'
  'cs_h3'
  'cs_h4'
  'cs_h5'
  'cs_h6'
  'cs_p'
]

@app.directive 'textBlockForm', ($filter) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'textBlock'
  controller: ($scope) ->
    @toolbar = [
      csElements
      ['bold','italics']
      ['csAlignLeft', 'csAlignCenter', 'csAlignRight', 'csAlignFull']
      ['html']
    ]

    # When the content changes, add the align-left default to any <p>
    $scope.$watch 'textBlock.content', (newValue) =>
      @content.description = $filter('paragraphAlignLeft')(newValue.description)

    # Paste text only
    @onPaste = (html) ->
      stripped = String(html).replace(/<[^>]+>/gm, '')
      stripped

    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/textBlock/textBlockForm.html"

@app.config ($provide) ->
  $provide.decorator 'taOptions', ['$delegate', (taOptions) ->
    taOptions.classes =
      toolbar: 'button-toolbar'
      toolbarGroup: 'button-group'
      toolbarButton: 'button small'
      toolbarButtonActive: 'active'
    taOptions
  ]

  $provide.decorator 'taOptions', ['taRegisterTool', '$delegate', 'taSelection', 'csFilepickerMethods', (taRegisterTool, taOptions, taSelection, csFilepickerMethods) ->

    setElementAlignment = (element, alignment) ->
      el = angular.element(element)
      alignments = ['left','center','right','full']
      for option in alignments
        if option == alignment
          el.addClass "align-#{option}"
        else
          el.removeClass "align-#{option}"

    setAlignment = (alignment) ->
      element = taSelection.getSelectionElement()
      contenteditable = element.hasAttribute('contenteditable')
      el = angular.element(element)
      if contenteditable
        angular.forEach el.children(), (childElement) ->
          setElementAlignment(childElement, alignment)
      else
        setElementAlignment(element, alignment)

    # Align Left button adds class align-left
    taRegisterTool 'csAlignLeft',
      iconclass: 'fa fa-align-left'
      action: ->
        setAlignment 'left'

      activeState: ->
        element = taSelection.getSelectionElement()
        el = angular.element(element)
        return el.hasClass('align-left')

    # Align Right button adds class align-right
    taRegisterTool 'csAlignRight',
      iconclass: 'fa fa-align-right'
      action: ->
        setAlignment 'right'

      activeState: ->
        element = taSelection.getSelectionElement()
        el = angular.element(element)
        return el.hasClass('align-right')

    # Align Center button adds class align-center
    taRegisterTool 'csAlignCenter',
      iconclass: 'fa fa-align-center'
      action: ->
        setAlignment 'center'

      activeState: ->
        element = taSelection.getSelectionElement()
        el = angular.element(element)
        return el.hasClass('align-center')

    # Align Full button adds class align-full
    taRegisterTool 'csAlignFull',
      iconclass: 'fa fa-align-justify'
      action: ->
        setAlignment 'full'

      activeState: ->
        element = taSelection.getSelectionElement()
        el = angular.element(element)
        return  el.hasClass('align-full')

    taRegisterTool 'csInsertImage',
      iconclass: 'fa fa-picture-o'
      action: ->
        filepicker.setKey "ANAsscmHGSKqZCHObvuK6z"
        filepicker.pickAndStore {mimetypes:['image/*'], services: ['COMPUTER']}, {location:"S3"},
        ((fpfiles) =>
          fpObject = fpfiles[0]
          cdnURL = csFilepickerMethods.cdnURL(fpObject.url)
          @$editor().wrapSelection 'insertImage', cdnURL
        )
        ((errorCode) ->
          console.log("FILEPICKER ERROR CODE: " + errorCode))

    activeState = (q) ->
      ->
        @$editor().queryFormatBlockState q

    # Sets default header classes
    # h1, h2, h3 will default the align-center
    # everything else defaults to align-left
    headerAction = ->
      name = @name.replace('cs_','')
      if name in ['h1','h2','h3']
        alignmentClass = 'align-center'
      else
        alignmentClass = 'align-left'
      @$editor().wrapSelection 'formatBlock', '<' + name + " class=' " + alignmentClass + "'>"

    angular.forEach csElements, (element) ->
      elementName = element.replace('cs_','')
      taRegisterTool element.toLowerCase(),
        buttontext: elementName
        action: headerAction
        activeState: activeState(elementName.toLowerCase())

    taOptions
  ]

# Filter is used to replace the default wrap <p> with <p align-left>
@app.filter 'paragraphAlignLeft', ->
  (input) ->
    pTagRegex = /<p>/
    input = input.replace(pTagRegex, (match) ->
      match.replace(/<[^>]+>/gm, "<p class='align-left'>")
    )
    input
