@app.directive 'heroSliderForm', (ComponentService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'heroSliderForm'
  controller: ->

    @themeOptions = ['light','dark','gray','orange']

    @heightOptions = ['standard', 'tall']
    if !@content.slideHeight
      @content.slideHeight = 'standard'

    @activeSlide = 0

    if @content.slides == undefined then @content.slides = []

    @addSlide = =>
      newSlide = ComponentService.newItem('hero').content
      @content.slides.push(newSlide)

    @setActiveSlide = (index) =>
      @activeSlide = index

    @deleteSlide = (index) =>
      @content.slides.splice(index,1)


    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/heroSlider/heroSliderForm.html"
