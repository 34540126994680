@app.directive 'csIngredientLink', ->
  restrict: 'A'
  scope: {}
  bindToController:
    ingredient: '='
  controllerAs: 'csIngredientLink'
  controller: ($element, $scope) ->
    $element.on 'click', (event) =>
      event.preventDefault()
      $scope.$emit 'showNellPopup',
        type: 'ingredient'
        data: @ingredient
    this
