@app.directive 'activityFeed', (AlgoliaSearchService, Mapper) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'matrix'
  controller: ->

    @applyClass = ->
      matrixClass = []
      matrixClass.push "matrix-#{@columns}-col"

      matrixClass.push "matrix-#{@itemType.toLowerCase()}"
      matrixClass.join ' '

    @doSearch = =>
      params = {
        difficulty: 'any'
        generator: 'chefsteps'
        published_status: 'published'
        page: '1'
        search_all: ''
        attributesToRetrieve: 'objectID,title,url,image,likes_count,description,has_video,activity_type'
        sort: 'newest'
      }
      mapper = Mapper.generate(['objectID', 'title', 'image', 'buttonMessage', 'url', 'likes_count', 'description', 'has_video', 'activity_type'])
      AlgoliaSearchService.search(params).then (data) =>
        numItems = @rows * @columns
        dataToMap = data.slice(0, numItems)
        @items = Mapper.mapObject(dataToMap, mapper)

    @showAllLikes = true

    this

  bindToController: {
    theme: '='
    rows: '='
    columns: '='
    itemType: '='
    buttonMessage: '='
    descriptionCharLimit: '='
  }

  link: (scope, element, attrs, controller) ->

    scope.$watch 'matrix.rows', (newValue, oldValue) ->
      if newValue
        controller.doSearch()

    scope.$watch 'matrix.columns', (newValue, oldValue) ->
      if newValue
        controller.doSearch()

  templateUrl: "/assets/templates/components/matrix/matrix.html"
