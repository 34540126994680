@app.directive 'csTypeFormBlock', (SurveyService) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    activityName: '='
    activityId: '='
    formId: '='
  }
  controllerAs: 'csTypeFormBlock'
  controller: ($state, $timeout, $scope, $window) ->
    @submitted = false

    initTFPopup = =>
      opts = {
        hidden: {
          recipe: @activityName,
          recipe_url: $window.location.href
        },
        onSubmit: (response) =>
          SurveyService.submit(@activityId, response.response_id)
            .then (res) =>
              @submitted = true
              
      }
      window.tf.createPopup @formId, opts

    @rateRecipe = =>
      @form.open()

    @form = initTFPopup()
    this
  templateUrl: "/assets/templates/directives/csTypeForm/csTypeFormBlock/csTypeFormBlock.html"
  