# Mostly used for comments to convert filepicker images

@app.filter 'smallImages', ->
  (html) ->
    h = $("<div>#{html}</div>")
    h = h.find('img')
      .filter( ->
        $(this).attr('src').match(/(filepicker)/)
      )
      .map( ->
        src = this.src.replace('www.filepicker.io', 'd3awvtnmmsvyot.cloudfront.net')
        this.src = src + '/convert?fit=crop&w=1000&rotate=exif&quality=60&cache=true&compress=true'
      )
      .end()
      .end()
      .end()
    h.html()

@app.filter 'smallImagesCDN', ->
  (html) ->
    h = $("<div>#{html}</div>")
    h = h.find('img')
      .filter( ->
        $(this).attr('src').match(/(d3awvtnmmsvyot.cloudfront.net)/)
      )
      .map( ->
        src = this.src
        this.src = src + '/convert?fit=crop&w=1000&rotate=exif&quality=60&cache=true&compress=true'
      )
      .end()
      .end()
      .end()
    h.html()
