# This exception override does do anything except logging to Rollbar and client console
@app.factory '$exceptionHandler', ($log) ->
  (exception, cause) ->
    exception.message += ' (caused by "' + cause + '")'

    # Log the error into rollbar
    Rollbar?.error exception

    # Default console logging behavior:
    $log.error exception
    return
