@app.directive 'headerForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'header'
  controller: ->

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/header/headerForm.html"
]
