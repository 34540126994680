@app.directive 'subscriptionButton', ($state, AnalyticsService) ->
  restrict: 'E'
  scope: {}
  bindToController: {
    label: '='
    alreadySubscribedText: '='
    theme: '='
    planId: '='
    isGift: '='
    isOrder: '='
    referrer: '='
  }
  controllerAs: 'subscriptionButton'
  controller: ($scope, subscriptionService, ModalService, UserAccountService) ->
    @canSubscribe = (@isOrder && !_.includes(_.get($state.currentUser, 'purchased_classes', []), @referrer)) || !@isOrder
    onSubscribeError = (errorType) ->
      AnalyticsService.track 'Subscription Button Error', {planId: @planId, errorType: errorType}

      $scope.showError = true
      $scope.errorType = errorType

    $scope.onDismissErrorClick = ->
      $scope.showError = false
    
    $scope.onClick = =>
      AnalyticsService.track 'Subscription Button Clicked', {planId: @planId, loggedIn: $state.currentUser?}
      if !$state.currentUser
        # ModalService.open('login', source: 'subscriptionButton')
        UserAccountService.redirectToAuthPage()
      else if @isOrder
        subscriptionService.placeOrder(@planId, onSubscribeError)
      else
        subscriptionService.subscribe(@planId, onSubscribeError, @isGift)
    
    this

  templateUrl: '/assets/templates/components/customButton/subscriptionButton/subscriptionButton.html'
