@app.controller 'matrixCollapsibleFormController', (ComponentService) ->
  @itemTypeOptions = ['List']

  @applyClass = =>
    matrixClass = []
    matrixClass.push "matrix-#{@columns}-col"

    matrixClass.push "matrix-#{@itemType.toLowerCase()}"
    matrixClass.join ' '

  @setItem = (selectedItem, currentItem) ->
    console.log 'setting item'
    angular.forEach currentItem.content, (value, key) ->
      if selectedItem.hasOwnProperty(key)
        currentItem.content[key] = selectedItem[key]

  @setNumItems = =>
    @numItems = @rows * @columns
    @deltaItems = angular.copy @items unless @deltaItems
    delta = @numItems - @deltaItems.length
    matrixItem = ComponentService.newItem('matrix')

    # If there are not enough items, then add new items
    if delta > 0
      @items = @deltaItems
      _.times delta, =>
        newMatrixItem = angular.copy matrixItem
        @items.push newMatrixItem

     # Only keep the items needed
    if delta < 0
      @items = @deltaItems.slice(0, @numItems)

  @deltaItems = angular.copy @items
  this

@app.directive 'matrixCollapsibleForm', (ComponentService) ->
  restrict: 'E'
  scope: {}
  controller: 'matrixCollapsibleFormController'
  controllerAs: 'matrixCollapsibleForm'

  bindToController: {
    title: '='
    items: '='
    showItemNumbers: '='
    rows: '='
    columns: '='
    itemType: '='
    theme: '='
    descriptionCharLimit: '='
  }

  templateUrl: "/assets/templates/components/matrixCollapsible/matrixCollapsibleForm.html"
