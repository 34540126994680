RANGE = 1500

onScrollAction = (w,s,p) ->
  if w.scrollY > p.offsetTop && w.scrollY < p.offsetTop + RANGE
    if !s.bannerJoule1.showBanner
      s.bannerJoule1.showBanner = true
  else
    if s.bannerJoule1.showBanner
      s.bannerJoule1.showBanner = false

@app.directive 'bannerJoule1', (AnalyticsService, $window) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerJoule1'
  controller: ->
    @showBanner = false
    @track = ->
      AnalyticsService.track 'Banner Clicked', {name: 'bannerJoule1'}

    this

  link: (scope, element, attrs) ->
    parent = angular.element(element).parent()

    angular.element($window).on 'scroll', _.throttle(->
      onScrollAction.call(this, $window, scope, parent[0])
    ,200)

    scope.$on '$destroy', ->
      angular.element($window).off 'scroll', onScrollAction

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/banner/bannerJoule1/bannerJoule1.html"
