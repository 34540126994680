@app.service 'csAdwords', ($rootScope, CookieConsentService) ->

  this.track = (google_conversion_id, google_conversion_label) ->
#    No longer google analytics service is used
#    if CookieConsentService.canDoAnalytics()
#      image = new Image(1,1)
#      image.src = "https://www.googleadservices.com/pagead/conversion/" + google_conversion_id + "/?label=" + google_conversion_label + "&script=0"

  this

@app.service 'csFacebookConversion', ($rootScope, CookieConsentService) ->

  this.track = (fb_pixel, fb_value) ->

  this
