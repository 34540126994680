@app.directive 'terms', (ModalService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @signup = ->
      signupSuccess = ->
        console.log 'Resolved signup'

      signupError = ->
        console.log 'Rejected signup'

      ModalService.open('signup', ModalService.modal.params).then signupSuccess, signupError
    this

  controllerAs: 'terms'
  templateUrl: "/assets/templates/modals/terms/terms.html"
