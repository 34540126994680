@app.filter 'ago', ->
  (input) ->
    if input
      moment(input).from(+Date.now())

moment.locale "en",
  relativeTime:
    future: "%s"
    past: "%s"
    s: "just now"
    m: "1 min ago"
    mm: "%d min ago"
    h: "1 hr ago"
    hh: "%d hrs ago"
    d: "1 day ago"
    dd: "%d days ago"
    M: "1 mo ago"
    MM: "%d mo ago"
    y: "1 yr ago"
    yy: "%d yr ago"
