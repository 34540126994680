@app.directive 'recover', (ModalService, AnalyticsService, $state, authenticationService, formHelper, alertService)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @ShowEmail404 = false

    @showErrorFor = (fieldName, validationParam) =>
      if formHelper.showErrorFor(@form, fieldName, validationParam)
        @ShowEmail404 = false
        true

    @close = ->
      ModalService.reject()

    @submit = =>

      recoverSuccess = =>
        @alertMessage = 'Please check your email for password recovery instructions.'
        AnalyticsService.track 'Modal Recover Submit Success', {url: $state.current.url}
        # $rootScope.$emit 'New Alert', {type: 'success', message: 'Please check your email for password recovery instructions.'}

        @showSpinner = false
        @ShowEmail404 = false
        ModalService.resolve()
        if $state.current.name == 'passwordReset'

          alertService.addAlert {type: 'success', message: @alertMessage}

          $state.go 'main.home'

      recoverError = =>
        AnalyticsService.track 'Modal Recover Submit Error', {url: $state.current.url}
        # $rootScope.$emit 'New Alert', {type: 'error', message: 'An error occured while registering your account.'}
        @showSpinner = false
        @ShowEmail404 = true

      if @form.$valid
        AnalyticsService.track 'Modal Recover Form Submitted', {url: $state.current.url}
        @showSpinner = true

        authenticationService
          .recover @form.email.$modelValue
          .then recoverSuccess, recoverError

    @signup = ->
      signupSuccess = ->
        console.log 'Resolved signup'

      signupError = ->
        console.log 'Rejected signup'

      ModalService.open('signup', ModalService.modal.params).then signupSuccess, signupError
    this

  controllerAs: 'recover'
  bindToController: {
    sso: '='
  }
  templateUrl: "/assets/templates/modals/recover/recover.html"
