@app.directive 'requirePassword', (ModalService, AnalyticsService, $state, authenticationService, $http, $rootScope)->
  restrict: 'E'
  scope: {}
  controller: ($scope) ->
    @email = ModalService.params().email

    @close = ->
      ModalService.reject()

    @submit = =>

      requirePasswordSuccess = =>
        AnalyticsService.track 'Modal Require Password Submit Success', {url: $state.current.url}
        $state.reload()

        @showSpinner = false
        ModalService.resolve()

      requirePasswordError = =>
        AnalyticsService.track 'Modal Require Password Submit Error', {url: $state.current.url}
        # $rootScope.$emit 'New Alert', {type: 'error', message: 'An error occured while registering your account.'}
        @showSpinner = false

      if @form.$valid
        AnalyticsService.track 'Modal Require Password Form Submitted', {url: $state.current.url}
        @showSpinner = true

        authenticationService
          .loginWithEmail @form.email.$modelValue, @form.password.$modelValue
          .then requirePasswordSuccess, requirePasswordError

    this

  controllerAs: 'requirePassword'
  templateUrl: "/assets/templates/modals/requirePassword/requirePassword.html"
