@app.directive 'signupButtonForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'signupButtonForm'
  controller: ->

  bindToController: {
    content: '='
  }
  templateUrl: "/assets/templates/components/customButton/signupButton/signupButtonForm.html"
]
