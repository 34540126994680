@app.directive 'signinButton', (ModalService) ->
  restrict: 'E'
  scope: {}
  controllerAs: 'signinButton'
  controller: ->
    @onClick = ->
      ModalService.open('login', source: 'customButton signinButton')

    this
  bindToController: {
    title: '='
    theme: '='
  }
  templateUrl: "/assets/templates/components/customButton/signinButton/signinButton.html"
