@app.service 'responsiveService', ($window) ->

  breakpoint = 700

  @isViewportLTE = (queryWidth) ->
    $window.innerWidth <= queryWidth

  @isViewportGT = (queryWidth) ->
    $window.innerWidth > queryWidth

  @isViewportMobile = =>
    @isViewportLTE(breakpoint)

  this
