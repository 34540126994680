@app.directive 'csSuggestedRecipes', ->
  restrict: 'E'
  scope: {}
  bindToController:
    title: '@'
    recipes: '='
  controllerAs: 'csSuggestedRecipes'
  controller: ($state) ->
    @handleClick = (url) ->
      slug = _.last url.split('activities/')
      $state.go("main.activity", {slug: slug})
    this
  templateUrl: "/assets/templates/directives/csSuggestedRecipes/csSuggestedRecipes.html"
