@app.directive 'csTypeForm', ->
  restrict: 'E'
  scope: {}
  bindToController: {}
  controllerAs: 'csTypeForm'
  controller: ($state, $timeout, $scope, CookieService) ->
    # Ref:- https://github.com/Typeform/embed/tree/main/packages/embed
    config = {
      '': {
        formId: 'k1QKJ94z'
        color: '#43413f'
        text: 'Talk to us...'
        launchType: 'time'
        customIcon: 'https://images.typeform.com/images/2gAGgr6YTPkf'
        launchValue: '10000'
      }
      'gallery': {
        formId: 'XzOhwDcu'
        color: '#43413f'
        text: 'Feedback'
        launchType: 'galleryScroll'
        customIcon: 'https://images.typeform.com/images/2gAGgr6YTPkf'
        launchValue: '60'
      }
      'activities': {
        formId: 'CAQDMTBW'
        color: '#43413f'
        text: 'Talk to us...'
        customIcon: 'https://images.typeform.com/images/2gAGgr6YTPkf'
        launchType: 'beforeElementReach'
      }
    }

    isTypeFormCookie = ->
      _.isEmpty(_.find(config, (setting, page) -> _.isEmpty(CookieService.get("cs-tf-#{setting.formId}")) == false))

    blockPrint = ->
      $('#tf-wrapper').addClass('no-print')

    launchOnScroll = (formId, options, height, limit) ->
      if $(window).scrollTop() > ((height / 100) * limit) && $('#tf-wrapper').children().length == 0 && isTypeFormCookie()
        window.tf.createSidetab formId, options
        blockPrint()

    launchOnElementReach = (formId, options) ->
      if ($("#discussion").length == 0 || document.getElementById('discussion').getBoundingClientRect().top < 1300) && $('#tf-wrapper').children().length == 0 && isTypeFormCookie()
        window.tf.createSidetab formId, options
        blockPrint()

    launchInTime = (formId, options, limit) ->
      if $('#tf-wrapper').children().length == 0
        $timeout(( -> window.tf.createSidetab formId, options), limit)
        blockPrint()

    # need to add timeout due to the us-sref are all navigating
    # the state instead of full page so immediately
    # so need to apply timeout
    $timeout(( ->
      setting = config[window.location.pathname.split('/')[1]]
      if setting && window.tf && isTypeFormCookie()
        opts = {
          container: document.getElementById('tf-wrapper')
          buttonText: setting.text
          buttonColor: setting.color
          customIcon: setting.customIcon
          onSubmit: (response) ->
            CookieService.set("cs-tf-#{setting.formId}", response.response_id,
              moment().add(csConfig.tfFormExpiry, csConfig.tfFormExpiryType).toDate())
            # remove the typeform after submitted
            $('#tf-wrapper').empty()
        }
        # Home Page
        if setting.launchType == 'time'
          launchInTime(setting.formId, opts, setting.launchValue)
        # Activity Page
        else if setting.launchType == 'beforeElementReach'
          $scope.$on 'throttledScroll', -> launchOnElementReach(setting.formId, opts)
        # Gallery Page
        else if setting.launchType == 'galleryScroll'
          $scope.$on 'contentChange', ->
            height = $(document).height()
            $scope.$on 'throttledScroll', -> launchOnScroll(setting.formId, opts, height, setting.launchValue)
    ), 500)

    this
  templateUrl: "/assets/templates/directives/csTypeForm/csTypeForm.html"
