@app.directive 'matrixFilter', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'matrixFilter'
  controller: ($scope) ->
    @showMore = false
    unless @tags
      @tags = []

    if @includeAll
      @activeTag = 'all'
    else
      @activeTag = @tags[0]

    @applyClass = =>
      matrixFilterClass = []
      matrixFilterClass.push "matrix-#{@columns}-col" if @columns

      matrixFilterClass.push "matrix-#{@itemType.toLowerCase()}" if @itemType
      matrixFilterClass.join ' '

    @setActiveTag = (tag) =>
      @activeTag = tag

    @hasActiveTag = (item) =>
      if @activeTag && (item.content.tags[@activeTag] || @activeTag == 'all')
        true
      else
        false

    @visibleItems = =>
      # @visibleRows * @columns
      visibleItems = @visibleRows * @columns
      if @showMore
        @filtered.length
      else
        visibleItems

    @toggleMore = =>
      @showMore = !@showMore

    this

  bindToController: {
    title: '='
    items: '='
    rows: '='
    columns: '='
    itemType: '='
    theme: '='
    itemTypeOptions: '='
    descriptionCharLimit: '='
    tags: '='
    includeAll: '='
    visibleRows: '='
  }

  templateUrl: "/assets/templates/components/matrixFilter/matrixFilter.html"
