@app.directive 'fiftyFiftyForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'fiftyFifty'
  controller: ->

    @alignTypeOptions = ['left', 'right']
    if !@content.alignType
      @content.alignType = 'left'

    @styleTypeOptions = ['full-bleed', 'padded']
    if !@content.styleType
      @content.styleType = 'full-bleed'

    @setItem = (selectedItem, currentItem)->
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]
    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/fiftyFifty/fiftyFiftyForm.html"
]
