# Factory that maps to api v0 components endpoint
@app.service 'UserAccountService', ($http, csConfig) ->
  @get = ->
    $http(
      method: 'GET'
      url: "#{csConfig.chefstepsEndpoint}/api/v0/users/account"
      headers:
        Authorization: "Bearer #{localStorage.getItem('access_token')}"
    )

  clearAllCookiesForDomain = ->
    domain = '.chefsteps.com'
    cookies = document.cookie.split('; ')
    i = 0
    while i < cookies.length
      cookie = cookies[i]
      eqPos = cookie.indexOf('=')
      name = if eqPos > -1 then cookie.substr(0, eqPos) else cookie
      # Delete cookie for the specified domain and all possible subdomains
      domainParts = domain.split('.')
      while domainParts.length > 0
        currentDomain = domainParts.join('.')
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=#{currentDomain}"
        domainParts.shift()
      i++
    return

  @removeLegacyStorage = ->
    legacyToken = localStorage.getItem('ls.user.token')
    if legacyToken
      localStorage.clear()
      sessionStorage.clear()
      clearAllCookiesForDomain()
#       window.location.replace('/account/en-us/auth')

  @redirectToAuthPage = ->
    currentPath = window.location.href
    window.location.replace("/account/en-us/auth?path=#{currentPath}")

  this


