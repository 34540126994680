# TODO: replace any bloom related code with third party comment system, e.g. Disqus

# TODO: fix known issue where when someone logs in, all the bloom stuff should update and reflect
# the proper log in state. e.g. we should not show the "Log in to join the discussion" button if the person
# has logged in sicne first viewing the page
@app.service 'csBloomManager', ($q, csConfig, $window, authenticationService, UserAccountService) ->

  # Single shared promise that gets resolved after Bloom is fully loaded
  # It is fine to hit bloom endpoints before this, like for getting comment counts
  # but you can't call anything on the Bloom object safely without using this.
  @deferred = $q.defer()
  @tagInserted = false
  @configured = false

  configure = (user) =>
    Bloom.configure {
      apiKey: 'xchefsteps'
      auth: if user then user.encrypted_bloom_info else null
      user: if user then String(user.id) else null
      env: csConfig.bloomEnv
    }
    @deferred.resolve()

  @insertTag = ->
    @tagInserted = true
    script = document.createElement "script"
    script.type = "text/javascript"
    script.src = "#{csConfig.bloomCommunityEndpoint}/export/loader.js"
    script.async = true
    script.setAttribute('crossorigin', 'anonymous')
    script.onload = =>
      # TODO: temporarily getting user credentials here, but @Hueezer will
      # add a central method for caching user credentials, at which point
      # this request will not be necessary, just access cached data
      if !@configured
        @configured = true
        UserAccountService.get()
          .then (user) ->
            configure(user['data'])
          .catch(
            -> configure null
          )
    document.head.appendChild script

  @loadBloom = =>
    if ! @tagInserted
      # Don't do this right away - bloom is kinda slow to load, and we aren't at the comments
      # part of the page right away, wait until everything including images are loaded.
      # Adding this check to see if the document is already ready.  Without this, $($window).load would never get fired because it has already happened.
      if document.readyState == 'complete'
        @insertTag()
      else
        $($window).load =>
          @insertTag()

    @deferred.promise

  this
