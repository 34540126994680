# Usage
# Add directive to input field
# %input(focus-on='exampleField')
# Anywhere in the app you can call:
# $scope.$broadcast 'exampleField'
# And it will focus to your field

@app.directive 'focusOn', ->
  link: (scope, element, attrs) ->
    scope.$on attrs.focusOn, (e) ->
      element[0].focus()

    scope.$on 'focusOff', (e) ->
      element[0].blur()
