@app.directive 'matrixItemIcon', (matrixItemService) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isRecipe = matrixItemService.isRecipe

    this
  controllerAs: 'matrixItemIcon'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemIcon/matrixItemIcon.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    loadImageOnEnterViewport: '='
    track: '&'
  }
