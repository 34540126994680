# Exposes APIs for getting structured data for product information
#
# https://developers.google.com/search/docs/guides/intro-structured-data
# https://support.google.com/merchants/answer/7331077

@app.service "ProductMetadataService", ($rootScope) ->

  # Sets the Product JSON LD data for the page to match the provided parameters
  # Will overwrite any existing Product JSON LD data
  @setJsonLdForProduct = (variants) ->
    content = JSON.stringify(getJsonLdForProduct(variants))
    setElement(content)

  reset = ->
    setElement("")

  setElement = (content) ->
    element = document.getElementById('json-ld-product')
    element.innerHTML = content

  getJsonLdForProduct = (variants) ->
    result = []
    for variant in variants
      result.push getJsonLdForVariant(variant)

    return result

  getJsonLdForVariant = (variant) ->

    availability = "http://schema.org/InStock"
    if variant.status == "pre_order"
      availability = "https://schema.org/PreOrder"
    else if !variant.orderable
      availability = "https://schema.org/OutOfStock"

    # Default to USD, but if it's not available then
    # use the first entry in the prices returned by the products group json
    currencies = Object.keys(variant.prices)
    if 'USD' in currencies and variant.prices['USD'] != 0 # hack because products group json returns 0 for USD outside of the US
      currency = 'USD'
    else
      currency = currencies[0]

    price = variant.prices[currency]

    return {
      "@context": "http://schema.org/",
      "@type": "Product",
      "image": variant.hero_image_url,
      "name": variant.google_merchant_name,
      "description": variant.google_merchant_description,
      "sku": variant.sku,
      "offers": {
        "@type": "Offer",
        "priceCurrency": currency,
        "price": price,
        "availability": availability,
      }
    }

  # Clear whenever state change, so that when navigating to non product pages we don't have any product JSON LD
  $rootScope.$on '$stateChangeStart', ->
    reset()

  this
