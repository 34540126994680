@app.directive 'comment', ($compile, $state, $http, CommentService, $timeout, ModalService)->
  restrict: 'E'
  replace: true
  scope: {}
  controllerAs: 'comment'
  controller: ($element)->
    @currentUser = $state.currentUser
    @showInput = false
    @editing = false

    # Comment Input Toolbar
    @toolbar = CommentService.toolbar

    @like = (commentId) =>
      CommentService.like(commentId).then (likeResponse) =>
        @commentData.likes.push(likeResponse)

    @unlike = (commentId) =>
      CommentService.unlike(commentId).then (unlikeResponse) =>
        console.log 'unlike! ', unlikeResponse
        console.log 'unlike likes: ', @commentData.likes

    @liked = =>
      CommentService.liked(@commentData.likes)

    # Toggle reply input form
    @reply = =>
      @showInput = !@showInput

    @focus = ->
      $timeout (->
        $element[0].querySelector("[ta-bind]").focus()
      ), 300

    # Add a comment
    @submit = =>
      comment =
        content: @input
        discussionId: @commentData.discussionId
        discussionType: 'post'
        parentCommentAuthor: @commentData.author.id
        parentCommentId: @commentData.id
      CommentService.post(comment).then (response) =>
        newComment = response.data.commentData
        newComment.children = []
        @commentData.children.push newComment
        @input = null
        @showInput = false

    @cancel = =>
      @showInput = false

    @canEdit = ->
      CommentService.canEdit(@commentData)

    # Toggle edit input form
    @edit = =>
      @editInput = angular.copy @commentData.content
      @editing = !@editing

    @cancelEdit = =>
      @editing = false

    @update = =>
      commentId = @commentData.id
      content = @editInput
      CommentService.put(commentId, content).then (response) =>
        @commentData.content = content
        @editing = false
        @editInput = null

    @delete = =>
      if confirm('Are you sure you want to delete this comment?')
        commentId = @commentData.id
        CommentService.delete(commentId).then (response) =>
          @commentData.deleted = true

    @login = ->
      ModalService.open('login')

    this

  link: (scope, element, attrs) ->

    if angular.isArray(scope.comment.commentData.children) && !scope.comment.commentData.deleted
      scope.comment.childDepth = scope.comment.parentDepth + 1
      commentsTemplate = $compile("<comments-list class='comment-children' comments='comment.commentData.children' depth='comment.childDepth'></comments-list>")(scope)
      element.append(commentsTemplate)

  bindToController: {
    commentData: '='
    parentDepth: '='
  }
  templateUrl: "/assets/templates/components/comments/comment/comment.html"
