@app.service 'InternationalService', ($state, $resource, $q, LocationService, $cookies, CookieService, csConfig) ->
  @location = LocationService.getCountry()

  @resource = $resource "#{csConfig.chefstepsEndpoint}/api/v0/shopping/countries", {},
    'index':
      method: 'GET'
    'set_country':
      url: "#{csConfig.chefstepsEndpoint}/api/v0/shopping/countries/set_country"
      method: 'POST'

  @loadData = =>
    deferred = $q.defer()

    if @countries
      deferred.resolve @countries
    else
      loadDataSuccess = (data) =>
        @countries = data
        deferred.resolve @countries
      loadDataError = (error) ->
        Rollbar.error("InternationalService - error while calling loadData at url: #{$state.current.url}, error: #{JSON.stringify(error)}")
        if error.status == 403
          $state.go '/logout'
        deferred.reject error

      @resource.index(loadDataSuccess, loadDataError)

    deferred.promise

  @getCountries = =>
    if @countries && @countries.length > 0
      @countries

  @setCountry = (countryCode) =>
    deferred = $q.defer()
    if @countries
      country = _.find @countries.countries, (country) -> country.code == countryCode
      dateNow = new Date()
      days = 7
      expires = new Date(dateNow.getTime() + days * 24 * 60 * 60 * 1000)
      
      CookieService.set('cs_geo', JSON.stringify({country: country.code}), expires)

      deferred.resolve({message: 'success'})
      deferred.promise
  this
