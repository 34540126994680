@app.directive 'csIngredients', ->
  restrict: 'E'
  scope: {}
  bindToController:
    ingredients: '='
    unit: '='
    isScalingMode: '='
    canRecieveFocusFromScaler: '=' # for setting focus when clicking scaling mode
    containerData: '='
    trustUnsanitized: '='
    allowEdit: '='
  controllerAs: 'csIngredients'
  controller: ($scope) ->
    $scope.$on 'focusOnCsIngredients', =>
      if @canRecieveFocusFromScaler
        $scope.$broadcast 'focusOnCsIngredient'
  templateUrl: "/assets/templates/directives/ingredients/csIngredients/csIngredients.html"
