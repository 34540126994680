@app.directive 'bannerTextForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerTextForm'
  controller: ->
    
    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/banner/bannerText/bannerTextForm.html"
]
