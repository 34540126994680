@app.directive 'matrixItemList', (matrixItemService, utilities, $location) ->
  restrict: 'E'
  scope: {}
  controller: ->
    @isRecipe = matrixItemService.isRecipe
    @urlMatches = ->
      $location.path() == @content.url

    @content.description = utilities.customSanitize(@content.description)
    this
  controllerAs: 'matrixItemList'
  templateUrl: "/assets/templates/components/matrix/matrixItem/matrixItemList/matrixItemList.html"
  bindToController: {
    content: '='
    descriptionCharLimit: '='
    showLikes: '='
    itemNumber: '='
    loadImageOnEnterViewport: '='
    track: '&'
  }
