@app.directive 'mediaForm', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'mediaForm'
  controller: ->
    @setItem = (selectedItem, currentItem)->
      console.log 'selectedItem: ', selectedItem
      console.log 'currentItem: ', currentItem
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]

    @viewerTypeOptions = ['standard', 'multi']
    this

  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/media/mediaForm.html"
