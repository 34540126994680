@app.directive 'csEmbedYoutube', (AnalyticsService) ->
  restrict: 'E'
  replace: false
  scope: {}
  bindToController:
    youtubeId: '@'
    adjustHeight: '@'
    loop: '@'
    autoplay: '@'
    controls: '@'
    mute: '@'
    tabIndex: '@'
    playVideo: '='
  controllerAs: 'csEmbedYoutube'
  controller: ($scope, $element, $attrs, $timeout) ->
    player = null
    playerId = "YT" + Date.now()
    initialLoad = false
    $($element).find('.video-iframe').attr('id', playerId)
    $($element).find('.video-iframe').attr('tabindex', @tabIndex)

    playerVars = {
      wmode: 'opaque'
      modestbranding: 1
      rel: 0
      showinfo: 0
      width: 1466
      iv_load_policy: 3
      autoplay: @autoplay || 1
      loop: @loop || 0
      playsinline : 0
      controls: @controls || 1
    }

    # If loop then we need to set playlist to the video Id so that it will actually loop
    # https://developers.google.com/youtube/player_parameters.html?playerVersion=HTML5#loop
    if @loop
      playerVars.playlist = @youtubeId

    createPlayer = =>
      if window.youtubeAPIReady && $attrs.youtubeId
        player = new YT.Player(
          playerId,
          videoId: @youtubeId
          host: 'https://www.youtube-nocookie.com'
          playerVars: playerVars

          events:
            # Youtube player is clever enough to default a playback quality based on size
            # but not to adjust it when going fullscreen.
            'onReady' : (event) =>
              player.setPlaybackQuality?('hd1080')
              $scope.$emit 'videoReady'
              initialLoad = true
              if @mute == 'true'
                player.mute()

              # @playVideo doesn't work for mobile for some reason. TODO look into why this is the case
              $scope.$watch 'csEmbedYoutube.playVideo', (playVideo) ->
                unless typeof playVideo == 'undefined'
                  if playVideo
                    player?.playVideo?()
                  else
                    player?.pauseVideo?()
            'onStateChange': (event) ->
              if event.data == YT.PlayerState.PLAYING && initialLoad
                AnalyticsService.track 'Video Played - Youtube ' + $attrs.youtubeId
                initialLoad = false
        )
      else
        # If the YT api isn't ready yet, try again a little later
        $timeout (-> createPlayer()), 500

    createPlayer()

    handlePlayVideoClicked = (e, isVideoPlaying) ->
      console.log 'videoplayerclickes'
      if isVideoPlaying
        if ! /(iPad|iPhone|iPod)/g.test( navigator.userAgent )
          player?.pauseVideo?()

      else
        player?.playVideo?()

      # Seems to help with occasional pillarboxing
      $timeout ( ->
        adjustHeight(1)
      ), 1000

    $attrs.$observe 'videoId', ->
      if player? && player.loadVideoById?
        # want cue, not load. Load starts it playing.
        player?.cueVideoById?($attrs.youtubeId, 0, 'hd1080')

    adjustHeight = =>
      unless @adjustHeight == 'false'
        newHeight = Math.round(getWidth() * ($attrs.aspectRatio || (9.0 / 16.0)))
        $($element).find('iframe').height(newHeight)

    getWidth = ->
      $($element).find('iframe').width()

    $scope.$watch getWidth, ( ->
      adjustHeight()
    ), true

    $scope.$on('playVideoClicked', handlePlayVideoClicked)

    this

  templateUrl: "/assets/templates/directives/csEmbedVideo/csEmbedYoutube/csEmbedYoutube.html"
