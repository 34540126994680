@app.directive 'jouleAppDemo', (AnalyticsService) ->
  restrict: 'E'
  replace: true
  scope: {}
  controllerAs: 'jouleAppDemo'
  bindToController: {
    videoPlaying: '='
  }
  controller: ($scope) ->
    @playEnabled = false
    @backgroundImage = 'https://d3awvtnmmsvyot.cloudfront.net/api/file/Biv95SWnQbSKJ8BMc8cK'
    @videoA =
      poster: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/60pEySmRSCKhIDsUapP9'
      mp4: 'https://d92f495ogyf88.cloudfront.net/static/wc1AbibpGkMij2n3ei2s/mcc2mbs.mp4'
    @videoB =
      poster: 'https://d3awvtnmmsvyot.cloudfront.net/api/file/iXGCIHGFQ2OjaInN9FXS'
      mp4: 'https://d92f495ogyf88.cloudfront.net/static/wc1AbibpGkMij2n3ei2s/jfc2mbs.mp4'

    @videoPlaying = 'B'

    @playVideo = (video) =>
      @videoPlaying = video

    @buttonClass = (video) =>
      if video == @videoPlaying
        'active'

    @enablePlay = ->
      @playEnabled = true

    @disablePlay = ->
      @playEnabled = false

    @track = ->
      AnalyticsService.track 'Joule App Demo Shown'

    this

  link: (scope, element, attrs) ->

  templateUrl: "/assets/templates/pages/joule/jouleAppDemo/jouleAppDemo.html"
