@app.directive 'standardButton', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'standardButton'
  controller: ->

  bindToController: {
    label: '='
    url: '='
    theme: '='
  }
  templateUrl: "/assets/templates/components/customButton/standardButton/standardButton.html"
]
