@app.directive 'bannerInlineForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerInlineForm'
  controller: ->

    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/banner/bannerInline/bannerInlineForm.html"
]
