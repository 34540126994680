@app.directive 'divider', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'divider'
  controller: ->

    @heightTypeClass = =>
      "divider-height-#{@content.heightType}"

    this

  bindToController: {
    theme: '='
    content: '='
  }
  templateUrl: "/assets/templates/components/divider/divider.html"
]
