@app.service 'alertService', ($rootScope, $timeout) ->

  @alerts = []

  @addAlert = (alert) =>
    @alerts.push(alert)
    # $rootScope.$broadcast "showNellPopup",
    #   include: '_alerts_popup.html'
    #   extraClass: 'alert-popup'
    #   closeCallback: => this.alerts = []

  @getAlerts = =>
    @alerts

  this
