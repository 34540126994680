@app.service 'suggestedRecipeService', ($q, AlgoliaSearchService) ->
  filterOwnActivity = (input) ->
    $q (resolve) ->
      resolve {results: input.results.filter((result) -> parseInt(result.objectID) != input.id), id: input.id}

  search = (input) ->
    $q (resolve) ->
      AlgoliaSearchService.search({search_all: input.query, attributesToRetrieve: 'objectID,title,url,image,likes_count,description,has_video,activity_type'})
        .then (response) -> resolve {results: response, id: input.id}

  maybeSearchAgain = (input) ->
    $q (resolve) ->
      if input.results.length > 2
        resolve input
      else
        resolve search({query: null, id: input.id}).then(filterOwnActivity)

  @getRecipes = (activity, n) ->
    $q (resolve) ->
      search({query: activity.tagList[0], id: activity.id})
        .then(filterOwnActivity)
        .then(maybeSearchAgain)
        .then(filterOwnActivity)
        .then((output) ->
          resolve output.results.slice(0, n))

  this
