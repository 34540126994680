@app.directive 'filepicker', (csFilepickerMethods)->
  restrict: 'E',
  replace: true,
  require: '?ngModel',
  # scope: true,
  scope: {
    imageType: '='
  }

  link: (scope, element, attrs, ngModel) ->
    filepicker.setKey "ANAsscmHGSKqZCHObvuK6z"
    scope.ngModel = ngModel
    scope.uploadProgress = -1

    target = $(element).find('.drop-target')

    filepicker.makeDropPane target,
      dragEnter: -> target.addClass("drop-ready")
      dragLeave: -> target.removeClass("drop-ready")
      onStart: ->
        scope.uploadProgress = 0
        target.removeClass("drop-ready")

      onProgress: (percentage) ->
        scope.uploadProgress = percentage

      onSuccess: (fpfiles) ->
        scope.ngModel.$setViewValue(JSON.stringify(fpfiles[0]))
        scope.uploadProgress = -1

      onError: (type, message) ->
        scope.uploadProgress = -1
        alert(message)

  controller: ($scope, $element) ->

    $scope.pickOrRemoveFile = ->
      filepicker.pickAndStore {mimetypes:['image/*', 'application/*', 'video/*'], services: ['COMPUTER']}, {location:"S3"},
      ((fpfiles) ->
        fpObject = fpfiles[0]
        cdnURL = csFilepickerMethods.cdnURL(fpObject.url)
        $scope.ngModel.$setViewValue(cdnURL)
        mimetype = fpObject.mimetype
        $scope.imageType = mimetype
      )
      ((errorCode) ->
        console.log("FILEPICKER ERROR CODE: " + errorCode))

    $scope.hasFile = ->
      $scope.ngModel? && $scope.ngModel.$modelValue? && ($scope.ngModel.$modelValue.length > 0)

    $scope.removeFile = ->
      $scope.ngModel.$setViewValue("")

  templateUrl: "/assets/templates/directives/filepicker/filepicker.html"
