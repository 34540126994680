### CHEFSTEPS NOTE!!!
**
** From: https://sroze.github.io/ngInfiniteScroll/# - v1.0.0 - 2013-02-23
**
** mnatkin 12/5/2014 - added hack to allow client to force a recheck with a broadcast.
** Since I was doing that I went ahead and converted to coffeescript and put it in our repo.
**
###

@app.directive 'infiniteScroll', ($rootScope, $window, $timeout, $interval) ->

  link: (scope, elem, attrs) ->
    checkWhenEnabled = undefined
    handler = undefined
    scrollDistance = undefined
    scrollEnabled = undefined
    $window = angular.element($window)
    scrollDistance = 0
    if attrs.infiniteScrollDistance != null
      scope.$watch attrs.infiniteScrollDistance, (value) ->
        scrollDistance = parseInt(value, 10)
    scrollEnabled = true
    checkWhenEnabled = false
    if attrs.infiniteScrollDisabled != null
      scope.$watch attrs.infiniteScrollDisabled, (value) ->
        scrollEnabled = !value
        if scrollEnabled and checkWhenEnabled
          checkWhenEnabled = false
          return handler()
        return

    handler = ->
      elementBottom = undefined
      remaining = undefined
      shouldScroll = undefined
      windowBottom = undefined
      windowBottom = $($window).height() + $($window).scrollTop()
      elementBottom = $(elem).offset().top + $(elem).height()
      remaining = elementBottom - windowBottom
      shouldScroll = remaining <= $($window).height() * scrollDistance
      if shouldScroll and scrollEnabled

        ### console.log("SCROLLING " + elementBottom + " " + $window.height()); ###

        if $rootScope.$$phase
          return scope.$eval(attrs.infiniteScroll)
        else
          return scope.$apply(attrs.infiniteScroll)
      else if shouldScroll
        return checkWhenEnabled = true
      return

    $window.on 'scroll', handler

    # No scroll or touchmove during momentum scroll events on
    # mobile so check periodically
    intervalPromise = $interval handler, 500

    ### mnatkin 12/5/2014 ###

    checkListenerDestroyer = $rootScope.$on 'contentChange', handler

    scope.$on '$destroy', ->
      $window.off 'scroll', handler
      $interval.cancel(intervalPromise)
      checkListenerDestroyer()

    $timeout (->
      if attrs.infiniteScrollImmediateCheck
        if scope.$eval(attrs.infiniteScrollImmediateCheck)
          return handler()
      else
        return handler()
      return
    ), 0

