@app.controller 'updateAddressController', ($rootScope, $state, customerOrder, CustomerOrderService, $stateParams, currentUser, ModalService) ->
  @orderId = $stateParams.id
  @currentUser = currentUser
  @success = false
  @updatable = customerOrder.shipping_address_updatable
  @updateMessage = customerOrder.update_message

  if @currentUser && @updatable
    @shippingAddress = customerOrder.shipping_address
    @fulfillmentStatus = customerOrder.fulfillment_status
    @content =
      title: 'Your shipping address is:'
      updateTitle: 'Update Your Shipping Address'
      successMessage: "Thank you! Your address update has been successfully submitted."

    @updateAddress = false
    @currentUserName = @shippingAddress.name
  else if !@updatable
    @showUpdateMessage = true
  else
    ModalService.open('login')

  @submit = =>
    addressData =
      address1: @form.address1.$modelValue
      address2: @form.address2.$modelValue
      city: @form.city.$modelValue
      province: @form.province.$modelValue
      zip: @form.zip.$modelValue

    CustomerOrderService.updateAddress {id: $stateParams.id, order: {shipping_address: addressData}}, ((data) =>
      @success = true
    ), (error) =>
      if error.status == 400
        @errorMessages = error.data.errors
      else
        @showEmailUs = true
  this
