@app.directive 'csIngredientsScaler', ->
  restrict: 'E'
  scope: {}
  bindToController:
    rescaleFactor: '='
    unit: '='
    isScalingMode: '='
    showPaywall: '='
  controllerAs: 'csIngredientsScaler'
  controller: ($scope) ->
    $scope.$watch (=> @rescaleFactor), =>
      if _.includes [0.5, 1, 2, 4], @rescaleFactor
        @scaleButtonValues = [0.5, 1, 2, 4]
      else
        values = [0.5, 1, 2]
        values.push @rescaleFactor
        values.sort (a,b) -> a - b
        @scaleButtonValues = values

    @handleClickScaleButton = ->
      $scope.$emit 'toggleScalingMode'

    @handleClickMultiplierButton = (factor) ->
      $scope.$emit 'rescaleIngredients', factor

    @handleClickUnitButton = (unit) ->
      $scope.$emit 'setUnit', unit

    this
  templateUrl: "/assets/templates/directives/ingredients/csIngredientsScaler/csIngredientsScaler.html"
