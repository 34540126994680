@app.filter 'ingredientQuantity', ->
  (input) ->
    input = parseFloat(input)
    if input == 0
      ''
    else if input - Math.floor(input) == 0
      Math.floor(input)
    else if input < 0.01
      input.toFixed(3)
    else if input < 0.1
      input.toFixed(2)
    else if input > 100
      Math.floor(input)
    else
      input.toFixed(1)

@app.filter 'ingredientUnit', ->
  (input) ->
    if input == "a/n"
      '-'
    else if input == "ea"
      ''
    else
      input
