@app.directive 'bannerForm', [ ->
  restrict: 'E'
  scope: {}
  controllerAs: 'bannerForm'
  controller: ->
    @typeOptions = ['Text', 'Inline Text', 'Joule 1', 'Joule Nav', 'Madlib', 'Premium Paywall', 'Premium Class']

    @setItem = (selectedItem, currentItem)->
      angular.forEach currentItem.content, (value, key) ->
        if selectedItem.hasOwnProperty(key)
          currentItem.content[key] = selectedItem[key]
    this
  bindToController: {
    content: '='
  }

  templateUrl: "/assets/templates/components/banner/bannerForm.html"
]
