@app.directive 'comments', ($http, csConfig, CommentService, $state, ModalService)->
  restrict: 'E'
  scope: {}
  controllerAs: 'comments'
  controller: ->
    @per = 20
    @currentPage = 1
    @currentUser = $state.currentUser

    # Comment Input Toolbar
    @toolbar = CommentService.toolbar

    @loadComments = (page=1,per) =>
      per = @per || 20
      @currentPage = page
      @showSpinner = true
      $http.get("#{csConfig.bloomApiEndpoint}/discussions/#{@discussionId}?apiKey=xchefsteps&sso=true&page=#{page}&per=#{per}").then (discussion) =>
        pageNums = Math.ceil(discussion.data.commentCount/per)
        @pages = _.range(1,pageNums + 1)
        @comments = discussion.data.comments
        @showSpinner = false

    # Load comments
    if @discussionId
      @loadComments(1,@per)

    # Add a comment
    @submit = =>
      comment =
        content: @input
        discussionId: @discussionId
        discussionType: 'post'
      CommentService.post(comment).then (response) =>
        newComment = response.data.commentData
        newComment.children = []
        @comments.push newComment
        @input = null

    @login = ->
      ModalService.open('login')

    @paginationClass = (page) =>
      if page == @currentPage
        'active'

    this

  link: (scope, element, attrs) ->

  bindToController: {
    theme: '='
    discussionId: '='
    commentsTitle: '='
    description: '='
    comments: '='
  }
  templateUrl: "/assets/templates/components/comments/comments.html"
