@app.directive 'promoPaywall', ->
  restrict: 'E'
  scope: {}
  controllerAs: 'promoPaywall'
  replace: true
  controller: ->

    contentMapping = {
      holidayNov: {
        title: "Make the holidays epic with a Studio Pass subscription."
        offer: "Get 20% off your first year."
        code: "TURKEY2023"
      }
      blackFriday: {
        title: "Studio Pass Black Friday Sale!"
        offer: "Get 50% off your first year."
        code: "BFSTUDIO2023"
      }
      holidayDec: {
        title: "Make the holidays epic with a Studio Pass subscription."
        offer: "Get 30% off your first year."
        code: "HOLIDAYS2023"
      }
      levelUpJan: {
        title: "Level up your cooking this year with a Studio Pass subscription."
        offer: "Get $24 off your first year."
        code: "STUDIOPASS24"
      }
    }

    @content = contentMapping[@couponType] || {
      title: null
      offer: null
      code: null
    }

    this

  link: (scope, element, attrs) ->

  bindToController: {
    content: '='
    couponType: '='
  }
  templateUrl: "/assets/templates/components/banner/promoPaywall/promoPaywall.html"
